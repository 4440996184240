import React, { useState } from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from '@material-ui/core/Container';
import Header from './Header';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../utils/AppContexts';
import { ApplicationPaths } from '../utils/AppConstants';
import userService from '../services/UserService';
import organizationService from '../services/OrganizationService';
import authService from '../api-authorization/AuthorizeService';
import { FeedbackBtn } from './FeedbackBtn';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  container: {
    paddingTop: theme.spacing(4),
  },
}));

const appNavRoutes = [
  {
    name: "Hem",
    route: ApplicationPaths.AppHome
  },
  {
    name: "Förrådet",
    route: ApplicationPaths.Inventories
  },
  {
    name: "Hitta",
    route: ApplicationPaths.Find
  },
];

const baseNavRoutes = [
  {
    name: "Appen",
    route: ApplicationPaths.AppHome
  },
  {
    name: "Info",
    route: ApplicationPaths.Info
  },
];

export function AppLayout(props) {

  return (
    <CommonLayout useAppNavRoutes={true} requireAuthenticatedUser={true}>
      {props.children}
    </CommonLayout>
  );
}

export function InfoLayout(props) {

  return (
    <CommonLayout useAppNavRoutes={false} requireAuthenticatedUser={false}>
      {props.children}
    </CommonLayout>
  );
}

function CommonLayout(props) {
  const classes = useStyles();
  const { useAppNavRoutes, requireAuthenticatedUser } = props;
  const [user, setUser] = useState(null);

  React.useEffect(() => {
    var populateUser = () => {
      userService.getUserInfo().then(user => {
        //console.log("User: ", user);
        setUser(user);
      });
    };

    if (requireAuthenticatedUser) {
      populateUser();
    } else {
      authService.isAuthenticated().then(isAuthenticated => {
        if (isAuthenticated) {
          populateUser();
        }
      })
    }
  }, [requireAuthenticatedUser]);

  let navRoutes = [];
  if (useAppNavRoutes) {
    //Använd app-menyn
    navRoutes = appNavRoutes;

    const hasAdminAccessToAnyOrganization = user && organizationService.hasAdminLinks(user.organizationLinks);
    if (hasAdminAccessToAnyOrganization) {
      //visa också menylänk till admin av organisationer
      navRoutes.push({
        name: "Admin",
        route: ApplicationPaths.Organizations
      })
    }
  } else {
    //använd infomenyn
    navRoutes = baseNavRoutes;
  }

  const content = !user && requireAuthenticatedUser
    ? <div>Loading user...</div>
    : <Container maxWidth="md" className={classes.container}>
      {props.children}
    </Container>;

  return (
    <div className={classes.root} >
      <CssBaseline />
      <UserContext.Provider value={user}>
        <Header navRoutes={navRoutes} />
        {content}
        <FeedbackBtn />
      </UserContext.Provider>
    </div>
  );
}