import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { ApplicationPaths } from './utils/AppConstants';
import invitesService from './services/InvitesService';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    equalHeight: {
        height: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const textsObj = {
  contact: (invite) => {
    return {
      cardTitle: "Ny inbjudan",
      cardBodyInvited: `Du har blivit inbjuden av ${invite.invitorName} att se hans/hennes utlåningslåda.`,
      cardBodyAccepted: `Nu kan du se ${invite.invitorName}s utlåningslåda.`,
    }
  },
  member: (invite) => {
    return {
      cardTitle: "Ny medlemsinbjudan",
      cardBodyInvited: `${invite.organizationName} har bjudit in dig att ta del av deras lånepool.`,
      cardBodyAccepted: `Nu kan du ta del av ${invite.organizationName}s lånepool.`,
    }
  },
};

export default function InviteCard(props) {
  const classes = useStyles();
  const { invite } = props;
  const texts = invite.organizationId
    ? textsObj.member(invite)
    : textsObj.contact(invite);

  const showButtonTo = invite.organizationId
    ? {
      //since the link is to the org rather than a specific inventory it could mean
      //multiple inventories. Therefore we redirect to inventories with orgId.
      //Proper action will be decided there (e.g. redirect to sole org inventory)
      pathname: ApplicationPaths.Inventories,
      state: { organizationId: invite.organizationId }
    }
    : {
      pathname: ApplicationPaths.Inventory,
      state: { inventoryId: invite.inventoryId }
    };


  const [isAccepted, setIsAccepted] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);

  let actionButton;

  let cardClasses = [];
  cardClasses.push(classes.root);
  if (props.equalizeHeight) {
    cardClasses.push(classes.equalHeight);
  }

  const handleAccept = (event) => {
    event.preventDefault();
    setIsAccepting(true);
    invitesService.acceptInvite(invite.inviteId).then(() => {
      setIsAccepted(true);
      setIsAccepting(false);
    });
  };



  const bodyText = isAccepted
    ? texts.cardBodyAccepted
    : texts.cardBodyInvited;

  if (isAccepted) {
    actionButton = <Button
      size="small"
      component={Link}
      to={showButtonTo}
      color="primary"
    >
      Visa
    </Button>
  } else {
    actionButton = <Button
      size="small"
      disabled={isAccepting}
      onClick={handleAccept}
      color="primary"
    >
      Acceptera
    </Button>
  }

  return (
    <Card className={cardClasses.join(" ")}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {texts.cardTitle}
        </Typography>
        <Typography variant="body2" component="p">
          {bodyText}
        </Typography>        
      </CardContent>
      <CardActions>
        {actionButton}
      </CardActions>
    </Card>
  );
}