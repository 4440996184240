import React, { useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, Typography } from '@material-ui/core';
import { UserContext } from '../utils/AppContexts';
import { Link, Redirect } from 'react-router-dom';
import { ApplicationPaths } from '../utils/AppConstants';
import claimsService from '../services/ClaimsService';

export function ClaimPublic(props) {

  const { location: { pathname } } = props;
  const externalClaimId = pathname.substring(ApplicationPaths.PublicClaimSection.length).replaceAll('/', '');

  const [claimLinkOrganizationName, setClaimLinkOrganizationName] = useState(null);
  const [claimLinkOrganizationLoaded, setClaimLinkOrganizationLoaded] = useState(false);

  React.useEffect(() => {
    if (!claimLinkOrganizationLoaded && externalClaimId) {

      claimsService.getClaimLinkOrganizationPublic(externalClaimId).then(organizationName => {
        setClaimLinkOrganizationName(organizationName);
        setClaimLinkOrganizationLoaded(true);
      });

    }
  }, [claimLinkOrganizationLoaded, externalClaimId]);

  const userInfo = useContext(UserContext);
  if (userInfo && externalClaimId) {
    return <Redirect to={ApplicationPaths.LoggedInClaimLink(externalClaimId)} />;
  }

  return (
    <main>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">Anslut dig!</Typography>
        </Grid>
        <Grid item md={8}>
          <Typography variant="h5" component="p" gutterBottom>
            Den här länken har skapats av {claimLinkOrganizationName} för att du ska kunna ansöka om att bli tillagd till deras medlemslista här på Lånepool. 
            Du får då tillgång till att visa och låna deras saker.
          </Typography>
          <Typography variant="body1" gutterBottom>
            För att kunna ansluta dig till {claimLinkOrganizationName} behöver du vara inloggad på Lånepool. Klicka på knappen nedan för att fortsätta och logga in med din användare eller skapa upp en ny.
          </Typography>
          <Button component={Link}
            to={ApplicationPaths.LoggedInClaimLink(externalClaimId)}
            variant="contained"
            color="primary"
          >
              Logga in och fortsätt
          </Button>
        </Grid>
      </Grid>
    </main>
  );
}
