import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Image as ImageIcon, Add as AddIcon } from '@material-ui/icons';
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { ListItemIcon, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Gravatar } from './CustomElements';

const useStyles = makeStyles((theme) => ({
  overline: {
    "line-height": 2,
    opacity: 0.8,
  },
  trailingIcon: {
    "justify-content": "flex-end",
  },
  addButton: {
    backgroundColor: theme.palette.accent.main,
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.accent.dark,
    }
  }
}));

export class ListItemData {
  constructor(key, primary, imageUrl, to, overline, icon) {
    this.key = key;
    this.primary = primary;
    this.imageUrl = imageUrl;
    this.to = to;
    this.overline = overline;
    this.icon = icon; //currently only support trailing icon
  }
}

//ListItemLink adapted from https://material-ui.com/guides/composition/#ListRouter.js
function ListItemLink(props) {
  const classes = useStyles();
  const { imageUrl, primary, to, overline, icon } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    [to],
  );

  const shouldRenderAsLink = !!to;

  let avatar = (<Avatar><ImageIcon /></Avatar>);
  if (imageUrl) {
    avatar = (<Avatar alt={primary} src={imageUrl} />);
    if (imageUrl.includes("@")) { //determined to be email.
      avatar = (<Gravatar alt={primary} email={imageUrl} />)
    }
  }

  let listItemText = (<ListItemText primary={primary} />);
  if (overline) {
    listItemText = (
      <ListItemText
        primary={
          <Typography
            variant='overline'
            className={classes.overline}
            display="block"
          >
            {overline}
          </Typography>
        }
        secondary={
          <Typography
            variant='subtitle1'
            component="span"
            display="block"
          >
            {primary}
        </Typography>
        } />
      );
  }

  let trailingIcon = null;
  if (icon && React.isValidElement(icon)) {
    console.log(icon);
    trailingIcon = <ListItemIcon className={classes.trailingIcon}> { icon }</ ListItemIcon>;
    }

    var listItem = (
    <ListItem button={shouldRenderAsLink} component={shouldRenderAsLink ? renderLink : "div"} disableGutters={true}>
      <ListItemAvatar>
        {avatar}
      </ListItemAvatar>
        {listItemText}
        {trailingIcon}
    </ListItem>
  );

  return (
    <li>
      {listItem}
    </li>
  );
}

export default function SimpleList(props) {

  const classes = useStyles();
  const { loading, items, onActivateEdit, editMinItems, emptyListText, addButtonText, addButtonLinkTo } = props;

  if (!items || !Array.isArray(items) || items.some(b => !(b instanceof ListItemData))) {
    throw new Error("Parameter 'items' has to be an array of ListItemData!");
  }

  //If editMinItems is set, then edit button should not show if items is less than number of items.
  const editCriteriaMet = !editMinItems
    ? true
    : items.length >= editMinItems;

  const editButton = onActivateEdit && editCriteriaMet
    ? (
      <Button
        variant="outlined"
        onClick={onActivateEdit}
      >
        Redigera
      </Button>)
    : null;

  const addButton = addButtonText && addButtonLinkTo
    ? (
      <Button
        className={classes.addButton}
        variant="contained"
        component={Link}
        to={addButtonLinkTo}
        startIcon={<AddIcon />}
      >
        {addButtonText}
      </Button>)
    : null;

  const simpleList = loading
    ? (<div>Loading...</div>)
    : (<>
      { items && items.length
        ? <List>
            {items.map((listItemData, index) => (
              <ListItemLink key={listItemData.key ?? index} {...listItemData}
              />
            ))}
        </List>
        : <Typography variant="body2" gutterBottom>{emptyListText}</Typography>
      }
      {addButton}
      {editButton}
    </>);
    

  return simpleList;
}