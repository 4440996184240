import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { Send as SendIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { InviteDTO } from '../utils/DTOs';
import { ApplicationPaths } from '../utils/AppConstants';
import invitesService from '../services/InvitesService';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.paper,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    outline: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const textsObj = {
  contact: {
    heading: "Lägg till kontakt",
    preamble: "Skicka en inbjudan att ta del av din utlåningslåda.",
    sendBtn: "Skicka inbjudan",
    confirmModal: {
      title: "Kontakt tillagd!",
      text: (email) => <>Din kontakt<em>{ email }</em> har nu fått en inbjudan till din låda.<br />
        Vill du bjuda in en till kontakt?</>, 
    }
  },
  member: {
    heading: "Lägg till medlem",
    preamble: "Bjud in en medlem till din organisation.",
    sendBtn: "Skicka inbjudan",
    confirmModal: {
      title: "Inbjudan skickad!",
      text: (email) => <>Din medlem<em>{email}</em> har nu fått en inbjudan till din organisation.<br />
        Vill du bjuda in en till medlem?</>, 
    }
  }
}

export function SendInvite(props) {
  const inventoryId = props.location.state.inventoryId;
  const organizationId = props.location.state.organizationId;

  const texts = organizationId ? textsObj.member : textsObj.contact;
  const returnTo = organizationId
    ? { pathname: ApplicationPaths.Organizations, state: { organizationId: organizationId } }
    : { pathname: ApplicationPaths.Contacts, state: { inventoryId: inventoryId } };

  const classes = useStyles();
  const [showModal, setShowModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [email, setEmail] = React.useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const inviteDTO = new InviteDTO(null, email, inventoryId, organizationId);

    invitesService.sendInvite(inviteDTO)
      .then(data => {        
        setIsSubmitting(false);
        handleToggleModal();
      })
      .catch(err => {
        //TODO: Create and show common error modal.
    });
  };

  const handleAddMoreFromModal = () => {
    setEmail('');
    handleToggleModal();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const modalBody = (
    <div className={classes.modal}>
      <h2 id="simple-modal-title">{texts.confirmModal.title}</h2>
      <p id="simple-modal-description">
        {texts.confirmModal.title}
      </p>

      <Button
        color="primary"
        className={classes.button}
        component={Link}
        to={returnTo}
      >
        Klar
            </Button>
      <Button
        color="primary"
        className={classes.button}
        onClick={handleAddMoreFromModal}
      >
        Lägg till fler
        </Button>
    </div>
  );

  return (
    <div className={classes.root}>
      <Typography variant="h1">      
        {texts.heading}
      </Typography>

      <Typography variant="body1">
        {texts.preamble}
      </Typography>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>       
        <TextField
          id="email"
          label="Epost"
          fullWidth
          margin="normal"
          variant="filled"
          value={email}
          onInput={e => setEmail(e.target.value)}
        />

        <Button
          variant="contained"
          color="primary"
          startIcon={<SendIcon />}
          type="submit"
          disabled={isSubmitting}
        >
          {texts.sendBtn}
        </Button>

        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={returnTo}
          disabled={isSubmitting}
        >
          Avbryt
        </Button>
      </form>

      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>

    </div>
  );
}