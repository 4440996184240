import authService from '../api-authorization/AuthorizeService'
import { ApiEndpoints } from '../utils/AppConstants';

export class ClaimsService {
  async createClaimLink(claimLinkDTO) {
    const token = await authService.getAccessToken();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(claimLinkDTO)
    };

    const response = await fetch(ApiEndpoints.CreateClaimLink, requestOptions)
      .then(response => {
        if (!response.ok) {
          console.error(response);
          throw new Error('Network response was not ok');
        }
        return response.json();
      });

    return response;
  }

  async getClaimLinkOrganizationPublic(externalId) {
    var url = ApiEndpoints.GetClaimLinkOrganizationPublic(externalId); 
    const result = await fetch(url).then(response => {
      if (!response.ok) {
        console.error(response);
        throw new Error('Network response was not ok');
      }
      return response.text();
    });

    return result;
  }

  async getClaimLink(externalId) {
    const token = await authService.getAccessToken();

    const requestOptions = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    };

    var url = ApiEndpoints.GetClaimLink(externalId);
    const result = await fetch(url, requestOptions).then(response => {
      if (!response.ok) {
        console.error(response);
        throw new Error('Network response was not ok');
      }
      return response.json();
    });

    return result;
  }

  async createAccessClaim(accessClaimDto) {
    const token = await authService.getAccessToken();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(accessClaimDto)
    };

    const response = await fetch(ApiEndpoints.CreateAccessClaim, requestOptions)
      .then(response => {
        if (!response.ok) {
          console.error(response);
          throw new Error('Network response was not ok');
        }
        return response.json();
      });

    return response;
  }

  async acceptClaim(accessClaimDto) {
    const token = await authService.getAccessToken();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(accessClaimDto)
    };

    const response = await fetch(ApiEndpoints.AcceptAccessClaim, requestOptions)
      .then(response => {
        if (!response.ok) {
          console.error(response);
          throw new Error('Network response was not ok');
        }
        return response.json();
      });

    return response;
  }

  async refuseClaim(accessClaimDto) {
    const token = await authService.getAccessToken();

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    };

    const response = await fetch(ApiEndpoints.RefuseAccessClaim(accessClaimDto.accessClaimId), requestOptions)
      .then(response => {
        if (!response.ok) {
          console.error(response);
          throw new Error('Network response was not ok');
        }
      });

    return response;
  } 

}

const claimsService = new ClaimsService();

export default claimsService;