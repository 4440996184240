import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    equalHeight: {
        height: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export class ButtonData {  
  constructor(actionLabel, actionLink, variant) {
    const allowedVariants = ["contained", "outlined"];

    this.actionLabel = actionLabel;
    this.actionLink = actionLink;

    if (!!variant && allowedVariants.includes(variant)) {
      this.variant = variant;
    } else if (!!variant) {
      throw new Error(`Parameter 'variant' with value ${variant} is not an allowed value. Allowed values are: ${allowedVariants.join(", ")}.`);
    }    
  }
}

export class CardData {
  constructor(heading, body, buttons) {
    this.heading = heading;
    this.body = body;

    if (!!buttons && Array.isArray(buttons) && !buttons.some(b => !(b instanceof ButtonData))) {
      this.buttons = buttons;
    } else if (!!buttons) {
      throw new Error("Parameter 'buttons' has to be an array of ButtonData!");
    }    
  }  
}

export default function SimpleCard(props) {
  const classes = useStyles();
  const { cardData } = props;
  let body, cardActions;

  let cardClasses = [];
  cardClasses.push(classes.root);
  if (props.equalizeHeight) {
    cardClasses.push(classes.equalHeight);
  }

  if (cardData.body) {
    body = (
      <Typography variant="body2" component="p">
        {cardData.body}
      </Typography>
    );
  }

  if (cardData.buttons) {
    cardActions = (
      <CardActions>
        {cardData.buttons.map((button, index) => {
          return <Button key={index} size="small" component={Link}
            to={button.actionLink}
            variant={button.variant}
            color="primary"
          >
            {button.actionLabel}
          </Button>
        })}        
      </CardActions>
      )
  }

  return (
    <Card className={cardClasses.join(" ")}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {cardData.heading}
        </Typography>
        {body}
      </CardContent>
      {cardActions}
    </Card>
  );
}