import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Close as CloseIcon } from '@material-ui/icons';
import { Link, Redirect } from 'react-router-dom';
import { ApplicationPaths } from '../utils/AppConstants';
import { IconButton, Grid, Typography } from '@material-ui/core';
import InfoHeader from '../InfoHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.paper,
  },
  itemImg: {
    maxWidth: "100%",
  },
  widthConstraint: {
    maxWidth: "calc(100% - 48px)", //Leave room for icon close
  }
}));

export function ViewItem(props) {
  const { item, isEditable } = props.location.state;
  const classes = useStyles();
  const [redirect, setRedirect] = React.useState(null);

  const redirectBack = () => {
    setRedirect({ pathname: ApplicationPaths.Inventory, state: { inventoryId: item.inventoryId } });
  }

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  const editButton = isEditable
    ? <div>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={{
            pathname: ApplicationPaths.EditItem,
            state: { item: item }
          }}
        >
          Redigera
        </Button>
      </div>
    : "";


  return (
    <div className={classes.root}>
      <Grid container
        justify="space-between"
      >
        <Grid item className={classes.widthConstraint} >
          <InfoHeader heading={item.name} />
        </Grid>
        <Grid item>
          <IconButton aria-label="Avbryt" onClick={redirectBack} >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      { item.imageUrl
        ? <img alt={item.name} src={item.imageUrl} className={classes.itemImg} />
        : null
      }

      {item.description ? <Typography variant="body1">{item.description}</Typography> : null}

      {editButton}

    </div>
  );
}