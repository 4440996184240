import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { UserContext } from '../utils/AppContexts';
import invitesService from '../services/InvitesService';
import { Typography } from '@material-ui/core';
import InviteCard from '../InviteCard';

const useStyles = makeStyles((theme) => ({
  root: {
    
  },  
}));

export function Invites(props) {
  const classes = useStyles();
  const userInfo = useContext(UserContext);

  const bodyText = userInfo.contactsInfo.inviteCount
    ? `Du har ${userInfo.contactsInfo.inviteCount} aktiva inbjudningar.`
    : "Du har inga aktiva inbjudningar."

  const [invites, setInvites] = useState([]);

  //useEffect pattern adapted from https://stackoverflow.com/a/53219430/2768430  
  React.useEffect(() => {
    invitesService.getInvites().then(invites => {
      setInvites(invites);
      //TODO! Uppdatera UserContext
    })
  }, []);

  return (
    <main className={classes.root}>

      <Typography variant="h1">
        Inbjudningar
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {bodyText}
      </Typography>

      <Grid container spacing={3}>
        {invites.map((invite, index) => (
          <Grid item xs={12} sm={6} key={invite.inviteId}>          
            <InviteCard invite={invite} equalizeHeight={true} />          
          </Grid>
        ))}
      </Grid>
    </main>
  );
}