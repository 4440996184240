import React from 'react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Grid, Typography } from '@material-ui/core';
import { Image as ImageIcon } from '@material-ui/icons';

const maxImageWidth = 640;
const maxImageHeight = 480;

const useStyles = makeStyles({
  fileInput: {
    display: 'none'
  },
  imageIcon: {
    'font-size': '6rem',
    'vertical-align': 'bottom',
  },
  imageButton: {
    display: 'inline-block',
    'vertical-align': 'bottom',
    'margin-bottom': 11,
  },
  previewImage: {
    //display: "inline-block",
    maxWidth: maxImageWidth,
    maxHeight: maxImageHeight,
    marginTop: 12,
    overflow: "hidden",
    "&.cropperOpen": {
      height: 0,
    },
    "& img": {
      maxWidth: "100%",
    }
  },
  cropperWrapper: {
    height: 0,
    overflow: 'hidden',

    '&.open': {
      height: 'initial',
    },
  }
});

/**
 * EditableImageData is a data class for ImageUpload component.
 * @class
 * @constructor
 * @public
 */
export class EditableImageData {
  constructor(initialImageUrl) {
    /**
      * initialImageUrl is the reference property for any initial imageUrl.
      * @type {string}
      * @public
      */
    this.initialImageUrl = initialImageUrl;

    /**
      * fullImage is a reference to the uploaded image object
      * @type {object}
      * @public
      */
    this.fullImage = null;
    /**
      * fullImageUrl is derived from property fullImage and holds the url to the fullSized image.
      * @type {string}
      * @public
      */
    this.fullImageUrl = null;
    /**
      * scaledImage is a reference to the scaled image object
      * @type {object}
      * @public
      */
    this.scaledImage = null;
    /**
      * scaledImageUrl is derived from property scaledImage and holds the url to the scaled image.
      * @type {string}
      * @public
      */
    this.scaledImageUrl = null;
  }
}

//https://github.com/fengyuanchen/cropperjs#getcroppedcanvasoptions
const cropOptions = {
  width: maxImageWidth,
  height: maxImageHeight,
  minWidth: maxImageWidth / 2,
  minHeight: maxImageHeight / 2,
  maxWidth: maxImageWidth,
  maxHeight: maxImageHeight,
  fillColor: '#fff',
  imageSmoothingEnabled: true,
  imageSmoothingQuality: 'high',
};

export default function ImageUpload(props) {
  const classes = useStyles();
  let fileInputRef, cropperRef = null;

  const { onChange } = props;
  const { initialImageUrl, fullImage, fullImageUrl, scaledImage, scaledImageUrl } = props.imageData;

  const [showCropper, setShowCropper] = React.useState(false);

  //State is handled in calling context. imageData is used for passing data to onChange callback.
  let imageData = new EditableImageData(initialImageUrl);
  imageData.fullImage = fullImage;
  imageData.fullImageUrl = fullImageUrl;
  imageData.scaledImage = scaledImage;
  imageData.scaledImageUrl = scaledImageUrl;

  const handleChange = (e) => {
    //handle upload of file. this goes into property fullImage. The scaledImage props are handled when cropper initializes.
    const image = e.target.files[0];
    if (image) {
      imageData.fullImage = image;
      if (imageData.fullImageUrl) {
        URL.revokeObjectURL(fullImageUrl);
      }
      imageData.fullImageUrl = URL.createObjectURL(image);
    }
    onChange(imageData);
  }

  const handleDelete = (e) => {
    e.preventDefault();
    //when fullImageProperty is set it means we have a new uploaded image. 
    //Then we have to reset both fullImage and scaledImage properties.
    if (imageData.fullImage) {
      imageData.fullImage = null;
      if (imageData.fullImageUrl) {
        URL.revokeObjectURL(fullImageUrl);
        imageData.fullImageUrl = ''; //don't use null for string as this will translate to "null" when posted as formdata
      }
      //setScaledImage(null);
      imageData.scaledImage = null;
      if (imageData.scaledImageUrl) {
        URL.revokeObjectURL(imageData.scaledImageUrl);        
        imageData.scaledImageUrl = ''; //don't use null for string as this will translate to "null" when posted as formdata
      }   

      try {
        fileInputRef.value = ''; //for IE11, latest Chrome/Firefox/Opera...
      } catch (err) {
        console.warn("Did not reset file input properly. Selecting the same file again won't trigger 'onInput' change event.");
      }
    } else {
      imageData.initialImageUrl = '';
    }
    onChange(imageData);
  }

  const setScaledImageFromCropper = (callback) => {
    const imageElement = cropperRef;
    if (imageElement) {
      const cropper = imageElement.cropper;
      const croppedCanvas = cropper.getCroppedCanvas(cropOptions);
      croppedCanvas.toBlob((blob) => {
        imageData.scaledImage = blob;
        if (scaledImageUrl) {
          URL.revokeObjectURL(scaledImageUrl);
        }
        const blobUrl = URL.createObjectURL(blob);
        imageData.scaledImageUrl = blobUrl;

        onChange(imageData);
        if (callback) {
          callback();
        }
      });
    }
  };

  const onCropperReady = () => {
    setScaledImageFromCropper();  
  }

  const onUseCrop = () => {
    setScaledImageFromCropper(() => setShowCropper(false)); 
  };

  let imagePreview = (<ImageIcon className={classes.imageIcon} />);
  let deleteButton = null;

  const imagePreviewUrl = scaledImageUrl ?? initialImageUrl; //uploaded scaled image url with fallback to initial imageUrl.
  if (imagePreviewUrl) {
    imagePreview = (<div className={classes.previewImage + (showCropper ? " cropperOpen" : "")}><img alt='' src={imagePreviewUrl} /></div>);    
    deleteButton = (<Button variant="outlined" onClick={handleDelete} className={classes.imageButton}>Ta bort</Button>);
  }

  const hasScaledImage = !!scaledImageUrl;

  const cropperView = fullImageUrl
    ? (<Cropper
      src={fullImageUrl}
      style={{ height: 400, width: "100%" }}
      crossorigin=""
      // Cropper.js options
      viewMode={2}
      aspectRatio={4 / 3}
      autoCropArea={1}
      dragmode={'move'}
      guides={false}
      checkOrientation={false} //works better without checking orientation. Also noted by react-cropper in their demo on https://codesandbox.io/s/wonderful-pine-i7fs3?file=/src/Demo.tsx. See also https://github.com/fengyuanchen/cropperjs/issues/671 
      ready={onCropperReady}
      ref={(r) => { cropperRef = r; }}
    />)
    : null;

  const showCropperButton = (<Button variant="outlined" color="primary" className={classes.imageButton} onClick={() => setShowCropper(true)}>Justera</Button>);
  const useCropButton = (<Button variant="outlined" color="primary" className={classes.imageButton} onClick={onUseCrop}>Använd utsnitt</Button>);

  return (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={12}>
        {imagePreview}
        <div className={classes.cropperWrapper + (showCropper ? " open" : "")} >
          {cropperView}
        </div>
      </Grid>
      {hasScaledImage && (
        <Grid item xs={12}>
          <Typography variant="body2">
            {showCropper
              ? 'Tryck "Använd utsnitt" när du är klar.'
              : 'Bilder sparas i formatet 4:3. Du kan justera utsnittet på bilden via knappen "Justera".'
            }
          </Typography>
        </Grid>
      )}      
      <Grid item>
        <input
          accept="image/*"
          className={classes.fileInput}
          id="image-file"
          type="file"
          onInput={handleChange}
          ref={(r) => { fileInputRef = r; }}
        />
        <label htmlFor="image-file" className={classes.imageButton}>
          <Button variant="outlined" color="primary" component="span">
            {imagePreviewUrl ? "Ändra bild" : "Lägg till bild"}
          </Button>
        </label>
      </Grid>
      {hasScaledImage && (
        <Grid item>
          {showCropper ? useCropButton : showCropperButton}
        </Grid>
      )}      
      <Grid item>
        {deleteButton}
      </Grid>
    </Grid>
  );
}