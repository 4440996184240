import authService from '../api-authorization/AuthorizeService'
import { ApiEndpoints } from '../utils/AppConstants';
import { ResultItemDTO } from '../utils/DTOs';

export class SearchService {

  /**
  * Returns search results for query
  * @param {string} query
  * @returns {ResultItemDTO[]}
  */
  async findItemsResult(query) {
    if (!query) {
      return [];
    }

    const token = await authService.getAccessToken();
    const url = ApiEndpoints.FindItemResult(query);
    const results = await fetch(url, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    const data = await results.json();
    
    if (data && data.length) {
      var resultItemDTOs = data.map(item => new ResultItemDTO(item));
      return resultItemDTOs;
    }

    return [];
  }

}

const searchService = new SearchService();

export default searchService;