import React from 'react';
import { Typography } from '@material-ui/core';

export function Anvandarvillkor(props) {
  return (
    <main>
      <Typography variant="h2" component="h1" gutterBottom>Användarvillkor</Typography>
      <Typography variant="h5" component="p" gutterBottom>
        Lånepool vill främja delningsekonomin i samhället genom att tillhandahålla en personlig anslagstavla grannar och 
        vänner emellan där de kan ange vilka verktyg och andra tillhörigheter de är villiga att låna ut. Användaren väljer 
        helt själv vem som ska kunna ta del av hens anslagstavla.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Lånepool hanterar inga transaktioner i samband med faktiskt utlåning. Eventuella lånevillkor sköts parterna emellan.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Lånepool tar inte ansvar för eventuella felaktigheter på tjänsten eller den information som sprids via sidan.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Du/Ni är ansvariga för den information som Ni publicerar och är skyldiga att se till så att den följer svensk lag.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Lånepool förbehåller sig rätten att plocka bort material som vi finner olämpligt.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Du bör inte ladda upp information som är hemlig eller känslig.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Vill du ta bort en utlåningslåda som du äger, maila till info@lanepool.se
      </Typography>
      <Typography variant="body1" gutterBottom>
        Lånepool skyddar informationen genom att följa allmänna rekommendationer om säkerhet på datorer och internet. Vår 
        webbplats använder kryptering genom SSL. 
      </Typography>
    </main>
  );
}
