import authService from '../api-authorization/AuthorizeService'
import { ApiEndpoints } from '../utils/AppConstants';

export class InventoryService {

  isAdminOrOwner(inventory) {
    return inventory.accessType === 1 || inventory.accessType === 2;
  }

  isPersonalAdministered(inventory) {
    return inventory.userId && this.isAdminOrOwner(inventory)
  }

  async getInventory(inventoryId) {
    const token = await authService.getAccessToken();
    const results = await fetch(ApiEndpoints.GetInventoryExpanded(inventoryId), {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    let inventory = await results.json();
    inventory.items = inventory.items ?? [];

    return inventory;
  }

  async getOwnInventories() {
    const token = await authService.getAccessToken();
    const results = await fetch(ApiEndpoints.GetInventoriesExpanded, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    const inventories = await results.json();
    
    return inventories;
  }

  async getSharedInventories() {
    const token = await authService.getAccessToken();
    const results = await fetch(ApiEndpoints.GetSharedInventories, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    const inventories = await results.json();

    return inventories;
  }

}

const inventoryService = new InventoryService();

export default inventoryService;