import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Box, Typography, Popover, Divider, Button } from '@material-ui/core';
import { ArrowDropDown, AccountCircle } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { ApplicationPaths, ApplicationPaths as AuthApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import { UserContext } from './utils/AppContexts';
import { Gravatar } from './CustomElements';

const useStyles = makeStyles((theme) => ({
  wideIconButton: {
    width: '100%',
    'justify-content': 'space-between',
  },
  popover: {
    textAlign: "center",
    minWidth: 250,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  largeGravatar: {
    margin: `${theme.spacing(1)}px auto`,
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  popoverButton: {
    margin: theme.spacing(1),
  },
  loginIconLink: {
    padding: "6px 12px",
  },
  loginIcon: {
    color: theme.palette.text.primary,
    width: 40,
    height: 40,
    opacity: 0.7,
  },
}));

export default function UserMenu() {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const popoverAnchorRef = React.useRef();

  const user = useContext(UserContext);

  if (!user) {
    return (
      <Box paddingY={1}>
        <Box paddingX={2}>
          <AccountCircle className={classes.loginIcon} />
        </Box>
        <Box paddingX={1} paddingTop={1}>
          <Button component={Link} to={ApplicationPaths.Login}>
            <Typography display='block' noWrap={true} variant='button'>
              Logga in
            </Typography>
          </Button>
        </Box>
      </Box>
      );
  }

  const userEmail = user.email;
  const userFullName = user.fullName;

  const handleClick = (event) => {
    setAnchorEl(popoverAnchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box paddingY={1}>
      <Box paddingX={2}>
        <Gravatar alt={userEmail} email={userEmail} />
      </Box>
      <Box paddingX={1} paddingTop={1}>
        <Button aria-controls="user-menu" aria-haspopup="true"
          onClick={handleClick}
          className={classes.wideIconButton}
          endIcon={<ArrowDropDown />}
          ref={popoverAnchorRef}
        >
          <Typography display='block' noWrap={true} variant='button'>
            {userFullName}
          </Typography>
        </Button>
        <Menu
          id="user-menu"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem component={Link} to={AuthApplicationPaths.Profile}>Hantera konto</MenuItem>
          {/*<MenuItem onClick={handleClose}>Byt till konto Per Jungnelius</MenuItem>*/}
          <MenuItem component={Link} to={{ pathname: `${AuthApplicationPaths.LogOut}`, state: { local: true } }}>Logga ut</MenuItem>
          </Menu>
      </Box>
    </Box>
  );
}

export function DesktopUserMenu() {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const user = useContext(UserContext);

  if (!user) {
    return (
      <Box paddingY={0.75}>
        <Button component={Link} className={classes.loginIconLink} to={ApplicationPaths.Login} title="Logga in">
          <AccountCircle className={classes.loginIcon} />
        </Button>
      </Box>
      )
  }

  const userEmail = user.email;
  const userFullName = user.fullName;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box paddingY={0.75}>
      <Button aria-describedby={id} onClick={handleClick}>
        <Gravatar alt={userEmail} email={userEmail} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
      >
        <Gravatar alt={userEmail} email={userEmail} className={classes.largeGravatar} />
        <Typography noWrap={true} variant='h6'>
          {userFullName}
        </Typography>
        <Typography noWrap={true} variant='body2' gutterBottom>
          {userEmail}
        </Typography>
        <Button variant="outlined" component={Link} to={AuthApplicationPaths.Profile} className={classes.popoverButton} >
          Hantera konto
        </Button>
        <Divider />
        <Button variant="outlined" component={Link} to={{ pathname: `${AuthApplicationPaths.LogOut}`, state: { local: true } }} className={classes.popoverButton}>
          Logga ut
        </Button>
      </Popover>      
    </Box>
  );
}