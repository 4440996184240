import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { Tab, Tabs, useTheme, Grid, Box } from "@material-ui/core";
import { Link, useLocation } from 'react-router-dom';
import { ApplicationPaths } from '../utils/AppConstants';
import UserMenu, { DesktopUserMenu } from '../UserMenu';

/*
 * Adapted from Responsive drawer on https://material-ui.com/components/drawers/ (sandbox: https://codesandbox.io/s/6khtm?file=/demo.js:6201-6210)
 */

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  appBar: {
    //backgroundColor: theme.palette.primary.light,
  },
  tabIndicator: {
    backgroundColor: theme.palette.accent.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  // necessary for content to be below app bar
  toolbarOffset: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  tab: {
    minHeight: 64,
  },
  rightAlignRestOfContent: {
    marginRight: "auto", //see https://stackoverflow.com/a/34063808/2768430    
  },
  logoLink: {
    display: "block",
    overflow: "hidden",
    marginTop: 5,
  },
  logoImageSvg: {
    height: 42,
    width: 170,
    fill: "#fff",
    float: "left",
  },
}));

export default function Header(props) {

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { window, navRoutes } = props;

  const theme = useTheme();
  const location = useLocation();
  const { pathname } = location;
  const classes = useStyles();

  //Get current path segment to know what should be selected in tab menu
  const navRouteRoutes = navRoutes.map(navRoute => navRoute.route).reverse(); //reverse in order to get match with root last
  let currentnavRouteSegment = navRouteRoutes.find(route => pathname.startsWith(route));

  //If below "Inventory" path, use "Inventories" route in menu.
  if (pathname.startsWith(ApplicationPaths.Inventory)) {
    currentnavRouteSegment = navRouteRoutes.find(route => route === ApplicationPaths.Inventories);    
  }

  //if no currentnavRouteSegment is found we should set the value to false
  currentnavRouteSegment = !currentnavRouteSegment ? false : currentnavRouteSegment;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <nav aria-label="navigation links">
      {<div className={classes.toolbarOffset} />}
      <Divider />
      <UserMenu />
      <Divider />
      <List>
        {navRoutes.map((navRoute, index) => (
          <ListItem button key={navRoute.name}
            selected={navRoute.route === currentnavRouteSegment}
            component={Link}
            to={navRoute.route}
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={navRoute.name} />
          </ListItem>
        ))}
      </List>
    </nav>
  );

  const tabs = (
    <Tabs
      aria-label="navigation"
      value={currentnavRouteSegment}
      classes={{ indicator: classes.tabIndicator }}
    >
      {navRoutes.map((navRoute, index) => (
        <Tab
          className={classes.tab}
          key={index}
          label={navRoute.name}
          component={Link}
          to={navRoute.route}
          value={navRoute.route}
        />
      ))}
    </Tabs>
  );

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>

          <Hidden smUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>

          <Box className={`${classes.rightAlignRestOfContent}`}>
            <a href={ApplicationPaths.Home} className={classes.logoLink} >
              {/*adapted from https://stackoverflow.com/a/39663457/2768430 */}
              <svg role="img" aria-label="lånepool logo" className={classes.logoImageSvg}>
                <use xlinkHref="/img/logo.svg#logo" />
              </svg>
            </a>
          </Box>

          <Hidden xsDown implementation="css">
            <Grid container justify="flex-end">
              <Grid item>
                {tabs}
              </Grid>
              <Grid item>
                <DesktopUserMenu />
              </Grid>
            </Grid>
          </Hidden>
        </Toolbar>
      </AppBar>
      {<div className={classes.toolbarOffset} />}
    </React.Fragment>
  );

}
