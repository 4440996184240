import React from 'react';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core';


export default function CustomTheme(props) {

  //adapted from https://saruwakakun.com/en/material-color#greenn
  const palette = {
    primary: {
      main: "#4CAF50",
    },
    secondary: {
      main: "#66BB6A",
    },
    accent: {
      light: "#fff350",
      main: "#FFC107",
      dark: "#c79100",
    },
    background: {
      default: "#fcfcfc",
      paper: "#fefefe",
    }
  }

  const typography = {
    h1: {
      fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeigth: 400,
    },
    h2: {
      fontFamily: '"Comfortaa", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeigth: 400,
    },
  }

  let theme = createMuiTheme({
    palette: palette,
    typography: typography,
  });

  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
    )
}
