import React from 'react';
import { Typography } from '@material-ui/core';


export default function InfoHeader(props) {
  const { heading, intro } = props;

  const headingEl = heading
    ? <Typography variant="h1">{heading}</Typography>
    : "";

  const introEl = intro
    ? <Typography variant="body1">{intro}</Typography>
    : "";

  return <>
    {headingEl}
    {introEl}
    </>
}