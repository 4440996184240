import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { ApplicationPaths } from '../utils/AppConstants';
import claimsService from '../services/ClaimsService';
import { AccessClaimDTO, ClaimLinkExpandedDTO } from '../utils/DTOs';

const useStyles = makeStyles((theme) => ({
  sendClaimButton: {
    backgroundColor: theme.palette.accent.main,
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.accent.dark,
    }
  }
}));

const textsObj = {
  claim: (claimLink) => {
    return {
      claimBody: `Ansök om att ta del av ${claimLink.organizationName}s lånepool. En administratör måste sen godkänna din ansökan.`,
      sendClaimButtonText: "Ansök",
      claimCreatedText: `Du har nu ansökt om att ta del av ${claimLink.organizationName}s lånepool. När din ansökan är godkänd kommer lånepoolen finnas i listan över dina lånepooler.`,
      alreadyHasAccessText: `På denna sida kan man ansöka om att ta del av ${claimLink.organizationName}s lånepool. Men du har redan tillgång till den, grattis! Du hittar den under menylänken "Förrådet".`,
      alreadyHasAccessClaimText: `På denna sida kan man ansöka om att ta del av ${claimLink.organizationName}s lånepool. Du har redan skickat in en ansökan! När en administratör godkänt din ansökan hittar du lånepoolen under menylänken "Förrådet".`,
    }
  }
};

export function ClaimLoggedIn(props) {

  const classes = useStyles();

  const { location: { pathname } } = props;
  const externalClaimId = pathname.substring(ApplicationPaths.LoggedInClaimSection.length).replaceAll('/', '');

  const [claimLinkExpanded, setClaimLinkExpanded] = useState(null);
  const [claimLinkLoaded, setClaimLinkLoaded] = useState(false);
  const [accessClaimCreated, setAccessClaimCreated] = useState(false);
  const [texts, setTexts] = useState({})
  //const [bodyText, setBodyText] = useState(null);

  const [isSendingClaim, setIsSendingClaim] = useState(false);

  React.useEffect(() => {
    if (!claimLinkLoaded && externalClaimId) {
      claimsService.getClaimLink(externalClaimId).then(result => {
        const claimLinkExpandedDto = ClaimLinkExpandedDTO.fromObj(result);
        setClaimLinkExpanded(claimLinkExpandedDto);
        setTexts(textsObj.claim(claimLinkExpandedDto));
        setClaimLinkLoaded(true);
      });

    }
  }, [claimLinkLoaded, externalClaimId]);

  //Utfall: 
  //1) Happy case - länken finns, du är ännu inte medlem
  //2) Länken finns, du är redan medlem
  //2) Länken finns, du har redan ansökt
  //3) Länken finns inte

  //setBodyText(texts.claimBody);
  let bodyText = texts.claimBody;
  let isClaimNotAvailable = false;
  if (claimLinkExpanded && claimLinkExpanded.hasUserAlreadyAccess) {
    bodyText = texts.alreadyHasAccessText;
    isClaimNotAvailable = true;
  } else if (claimLinkExpanded && claimLinkExpanded.hasUserAlreadyAccessClaim) {
    bodyText = texts.alreadyHasAccessClaimText;
    isClaimNotAvailable = true;
  }


  const handleSendClaim = (event) => {
    event.preventDefault();
    setIsSendingClaim(true);

    const accessClaimDto = new AccessClaimDTO(null, null, claimLinkExpanded.organizationId, null, claimLinkExpanded.claimLinkId);

    claimsService.createAccessClaim(accessClaimDto).then(() => {
      setAccessClaimCreated(true);
      setIsSendingClaim(false);
    });
  };

  //Knapp för att ansöka
  const button = <Button
    className={classes.sendClaimButton}
    variant="contained"
    disabled={isSendingClaim || accessClaimCreated || isClaimNotAvailable}
    onClick={handleSendClaim}
    startIcon={<AddIcon />}
  >
    {texts.sendClaimButtonText}
  </Button>


  //Feedback när man ansökt


  return (
    <main>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">Gå med i en gemenskap!</Typography>
        </Grid>
        {!bodyText ? "" :
          <Grid item xs={12} sm={7}>
            <Typography variant="body1">{bodyText}</Typography>            
          </Grid>
        }
        {!accessClaimCreated ? "" :
          <Grid item xs={12} sm={7}>
            <Typography variant="body1">{texts.claimCreatedText}</Typography>
          </Grid>
        }
        <Grid item xs={12} sm={7}>
          {button}
        </Grid>
      </Grid>
    </main>
  );
}
