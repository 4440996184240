import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { Add as AddIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { ItemDTO } from '../utils/DTOs';
import authService from '../api-authorization/AuthorizeService';
import { ApiEndpoints, ApplicationPaths } from '../utils/AppConstants';
import ImageUpload, { EditableImageData } from '../ImageUpload';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    outline: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export function AddItem(props) {
  const inventoryId = props.location.state.inventoryId;

  const classes = useStyles();
  const [showModal, setShowModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [imageData, setImageData] = React.useState(new EditableImageData(''));

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);    
    
    authService.getAccessToken().then(token => {

      const itemDTO = new ItemDTO(null, null, name, null, description, inventoryId);

      if (imageData.fullImage && imageData.scaledImage) {
        //This requires both fullImage (uploaded file) and scaledImage (shrunk/cropped file) to be populated.
        //Image blob is taken from scaledImage and name is taken from uploaded file.
        itemDTO.image = imageData.scaledImage;
        itemDTO.imageUrl = imageData.fullImage.name;
      } else {
        itemDTO.imageUrl = imageData.initialImageUrl;
      }

      //add item to formdata in order to post file
      var formData = new FormData();
      for (var key in itemDTO) {
        formData.append(key, itemDTO[key]);
      }

      const requestOptions = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData
      };
      fetch(ApiEndpoints.CreateItem, requestOptions)
        .then(response => response.json())
        .then(data => {
          setIsSubmitting(false);
          handleToggleModal();
        });
    });
  };

  const handleAddMoreFromModal = () => {
    setName('');
    setDescription('');
    setImageData(new EditableImageData(''));
    handleToggleModal();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const backToInventoryLinkObj = { pathname: ApplicationPaths.Inventory, state: { inventoryId: inventoryId } };

  const modalBody = (
    <div className={classes.modal}>
      <h2 id="simple-modal-title">Tillagt!</h2>
      <p id="simple-modal-description">
        Nu har du lagt till en <em>{name}</em>.<br />
                    Vill du lägga till något mer?
            </p>

      <Button
        color="primary"
        className={classes.button}
        component={Link}
        to={backToInventoryLinkObj}
      >
        Klar
            </Button>
      <Button
        color="primary"
        className={classes.button}
        onClick={handleAddMoreFromModal}
      >
        Lägg till fler
        </Button>
    </div>
  );

  return (
    <div className={classes.root}>
      <h1>
        Lägg till
            </h1>

      <p>
        Börja med namnet. Lägg sen till en bild och en beskrivning.
            </p>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          id="name"
          label="Namn"
          fullWidth
          margin="normal"
          variant="filled"
          value={name}
          onInput={e => setName(e.target.value)}
        />

        <ImageUpload
          imageData={imageData}
          onChange={(imageData) => setImageData(imageData)}
        />

        <TextField
          id="description"
          label="Beskrivning"
          fullWidth
          margin="normal"
          multiline
          variant="filled"
          value={description}
          onInput={e => setDescription(e.target.value)}
        />

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          type="submit"
          disabled={isSubmitting}
        >
          Lägg till
                </Button>

        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to={backToInventoryLinkObj}
          disabled={isSubmitting}
        >
          Avbryt
        </Button>
      </form>

      <Modal
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>

    </div>
  );
}