import React from 'react';
import { Tab, Tabs, AppBar, Fab } from '@material-ui/core';
import { Image as ImageIcon, Add as AddIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';
import { ApplicationPaths } from './utils/AppConstants';

const useStyles = makeStyles((theme) => ({  
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(5),
    right: theme.spacing(2),
    backgroundColor: theme.palette.accent.main,

    '&:hover': {
      backgroundColor: theme.palette.accent.dark,
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      padding: 32,
      'border-radius': '50%',
      background: '#fff',
      'z-index': -1,
    },
  },
}));

export default function InventoryContactsBar(props) {
  const classes = useStyles();
  const { value, fabTo, inventory } = props;

  const fab = fabTo
    ? (
      <Fab aria-label="add" className={classes.fab} component={Link} to={fabTo} >
        <AddIcon />
      </Fab>
    )
    : null;

  return (
    <AppBar position="fixed" color="secondary" className={classes.appBar}>
      <Tabs
        variant="fullWidth"
        aria-label="nav tabs example"
        value={value}
      >
        <Tab
          icon={<ImageIcon />}
          label="Saker"
          component={Link}
          to={{
            pathname: ApplicationPaths.Inventory,
            state: { inventory: inventory }
          }}
          value={ApplicationPaths.Inventory}
        />
        <Tab
          icon={<ImageIcon />}
          label="Kontakter"
          component={Link}
          to={{
            pathname: ApplicationPaths.Contacts,
            state: { inventory: inventory }
          }}
          value={ApplicationPaths.Contacts}
        />
      </Tabs>

      {fab}

    </AppBar>
    );
}