import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Search as SearchIcon} from '@material-ui/icons';
import searchService from '../services/SearchService';
import SimpleList, { ListItemData } from '../SimpleList';
import { ApplicationPaths } from '../utils/AppConstants';
import { Typography } from '@material-ui/core';
import { UserContext } from '../utils/AppContexts';

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.paper,
  },  
  overline: {
    "line-height": 2,
  }
}));

export function FindItem(props) {
  const userInfo = useContext(UserContext);
 
  const classes = useStyles();
  const [query, setQuery] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [searchResult, setSearchResult] = React.useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    searchService.findItemsResult(query).then((data) => {
      setIsSubmitting(false);
      console.log(data);
      setSearchResult(data);
    })
  };

  let resultList = null;
  if (searchResult) {
    resultList = <SimpleList
      items={searchResult.map(result => new ListItemData(result.itemId, result.name, result.imageUrl, {
        pathname: ApplicationPaths.ViewItem,
        state: { item: result }
      }, result.owner))}
      emptyListText={`Din sökning på "${query}" gav inget resultat.`}
     />
  }

  return (
    <div className={classes.root}>
      <Typography variant="h1">
        Hitta pryl
      </Typography>
      <Typography variant="subtitle1">
        Sök bland dina {userInfo.contactsInfo.contactCount} kontakters {userInfo.contactsInfo.toolCount} prylar.
      </Typography>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          id="query"
          label="Sök"
          fullWidth
          margin="normal"
          variant="filled"
          value={query}
          onInput={e => setQuery(e.target.value)}
        />        

        <Button
          variant="contained"
          color="primary"
          startIcon={<SearchIcon />}
          type="submit"
          disabled={isSubmitting}
        >
          Sök
                </Button>
      </form>

      {resultList}      
    </div>
  );
}