import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../utils/AppContexts';
import { Button, Checkbox, FormControlLabel, Modal, TextField } from '@material-ui/core';
import FeedbackIcon from '@material-ui/icons/Feedback';
import feedbackService from '../services/FeedbackService'


const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    right: -112,
    bottom: theme.spacing(10),
    transform: "rotate(-90deg)",
    transformOrigin: "bottom left",

  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    outline: 0,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  startCollapsed: {
    maxHeight: 0,
    overflow: "hidden",
    transition: "max-height 0.5s ease",
  },
  expanded: {
    maxHeight: 100,
  }

}));

export function FeedbackBtn(props) {
  const classes = useStyles();
  const user = useContext(UserContext);

  const [showModal, setShowModal] = React.useState(false);
  const [feedbackText, setFeedbackText] = React.useState('');
  const [wantFeedback, setWantFeedback] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const userEmail = wantFeedback ? email : '';
    feedbackService.sendMessage(feedbackText, userEmail)
      .then(result => {
        setFeedbackText('');
        setWantFeedback(false);
        setEmail('');
        setIsSubmitting(false);
        setShowModal(false);
      })
    console.log("submit", event);

  }

  const handleWantFeedBackChange = (event) => {
    const newWantFeedbackValue = !wantFeedback;
    setWantFeedback(newWantFeedbackValue);
    if (newWantFeedbackValue && !email) {      
      if (user && user.email) {
        setEmail(user.email);
      }
    }
  };

  const handleToggleModal = () => {
    setShowModal(!showModal);
  }

  const modalBody = (
    <div className={classes.modal}>
      <h2 id="simple-modal-title">Ge feedback!</h2>
      <p id="simple-modal-description">
        Vi blir superglada för all återkoppling! Ris, ros, frågor och förbättringsförslag! Du kan vara helt anonym.
            </p>

      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          id="feedbackText"
          label="Feedback"
          fullWidth
          margin="normal"
          multiline
          rows={3}
          variant="filled"
          value={feedbackText}
          onInput={e => setFeedbackText(e.target.value)}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={wantFeedback}
              onChange={handleWantFeedBackChange}
              name="wantFeedback"
            />
          }
          label="Jag vill ha återkoppling på min återkoppling."
        />

        <TextField
          className={classes.startCollapsed + (wantFeedback ? " " + classes.expanded : "")}
          id="email"
          label="Epost"
          disabled={!wantFeedback}
          fullWidth
          margin="normal"
          variant="filled"
          value={email}
          onInput={e => setEmail(e.target.value)}
        />

        <Button
          color="primary"
          className={classes.button}
          type="submit"
          disabled={isSubmitting}
        >
          Skicka
        </Button>
        <Button
          color="primary"
          className={classes.button}
          onClick={handleToggleModal}
          disabled={isSubmitting}
        >
          Avbryt
        </Button>

      </form>

    </div>
  );

  return (
    <div className={classes.root} >
      <Button
        variant="contained"
        color="secondary"
        size="small"
        className={classes.button}
        startIcon={<FeedbackIcon />}
        onClick={handleToggleModal}
      >
        Feedback
      </Button>

      <Modal
        open={showModal}
        onClose={handleToggleModal}
        aria-labelledby="feedback-modal-title"
        aria-describedby="feedback-modal-description"
      >
        {modalBody}
      </Modal>
    </div>
  );
}