//import React, { Component } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SimpleCard, { CardData, ButtonData } from '../SimpleCard';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bgImage: {
    backgroundImage: "url(/img/bgs/tools_w720.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "absolute",
    width: "100%",
    height: "calc(100% - 64px)",
    paddingBottom: "32%",
    left: 0,
    marginTop: "-20px",
    opacity: 0.4,
  },
  bgImageForeground: {
    position: "relative",
  },
}));

const cards = [
  new CardData("Med på tåget redan?", "Då är det bara att köra!", [new ButtonData("Till appen", "/app", "contained")]),
  new CardData("Ny i krokarna?", "Är du redo att börja dela? Eller vill du veta lite mer först?", [new ButtonData("Registrera", ApplicationPaths.Register, "contained"), new ButtonData("Läs mer", "/info", "outlined")])
];


export function Home(props) {
  const classes = useStyles();

  return (
    <main>
      <div className={classes.bgImage}></div>
      <Grid container spacing={3} className={classes.bgImageForeground} >
        <Grid item xs={12}>
          <Typography variant="h1">Dela mera!</Typography>
        </Grid>

        {cards.map((cardData, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <SimpleCard cardData={cardData} equalizeHeight={true} />
          </Grid>
        ))}
      </Grid>
    </main>
  );
}
