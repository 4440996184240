import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Button as SpacingEnabledButton} from '../CustomElements'
import { Link, Redirect } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import { ApiEndpoints, ApplicationPaths } from '../utils/AppConstants';
import { Grid } from '@material-ui/core';

import ImageUpload, { EditableImageData } from '../ImageUpload';
import SimpleDialog from '../SimpleDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.paper,
  },  
}));


export function EditItem(props) {
  //console.log(props);
  const item = props.location.state.item;

  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [name, setName] = React.useState(item.name);
  const [description, setDescription] = React.useState(item.description ?? '');
  const [imageData, setImageData] = React.useState(new EditableImageData(item.imageUrl));
  
  const [redirect, setRedirect] = React.useState(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const dialogTitle = "Bekräfta borttagning";
  const dialogContent = `Är du säker på att du vill ta bort ${item.name}?`;
  const dialogCancelText = "Avbryt";
  const dialogConfirmText = "Ta bort";

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  }

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  }

  const handleConfirmDelete = (item) => {
    setIsSubmitting(true);
    authService.getAccessToken().then(token => {
      const requestOptions = {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      fetch(ApiEndpoints.DeleteItem(item.itemId), requestOptions)
        .then(response => {
          //console.log(response);
          setIsSubmitting(false);
          setIsDialogOpen(false);
          setRedirect({ pathname: ApplicationPaths.Inventory, state: { inventoryId: item.inventoryId } })
        })
    })
  }

  const redirectBack = (updatedItem) => {
    setRedirect({
      pathname: ApplicationPaths.ViewItem,
      state: { item: updatedItem ?? item }
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    authService.getAccessToken().then(token => {
      //set properties
      item.description = description;
      item.name = name;

      if (imageData.fullImage && imageData.scaledImage) {
        //This requires both fullImage (uploaded file) and scaledImage (shrunk/cropped file) to be populated.
        //Image blob is taken from scaledImage and name is taken from uploaded file.
        item.image = imageData.scaledImage;
        item.imageUrl = imageData.fullImage.name;
      } else {
        item.imageUrl = imageData.initialImageUrl;
      }

      //add item to formdata in order to post file
      var formData = new FormData();
      for (var key in item) {
        formData.append(key, item[key]);
      }

      const requestOptions = {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      };
      fetch(ApiEndpoints.UpdateItem, requestOptions)
        .then(response => {
          //console.log(response);
          setIsSubmitting(false);

          response.json()
            .then(updatedItemDto => {
              redirectBack(updatedItemDto);
            });
        })
    });
  };

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <div className={classes.root}>

      <h1>
        Redigera pryl
      </h1>  
          
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>

        <TextField
          id="name"
          label="Namn"
          fullWidth
          margin="normal"
          variant="filled"
          value={name}
          onInput={e => setName(e.target.value)}
        />

        <ImageUpload
          imageData={imageData}
          onChange={(imageData) => setImageData(imageData)}
        />

        <TextField
          id="description"
          label="Beskrivning"
          fullWidth
          margin="normal"
          multiline
          variant="filled"
          value={description}
          onInput={e => setDescription(e.target.value)}
        />

        <Grid container
          justify="space-between"
        >
          <Grid item>
            <SpacingEnabledButton
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
              disabled={isSubmitting}
              mr={2}
            >
              Spara ändringar
            </SpacingEnabledButton>

            <Button
              variant="outlined"
              className={classes.button}
              disabled={isSubmitting}
              onClick={handleOpenDialog}
            >
              Ta bort
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              disabled={isSubmitting}
              component={Link}
              to={{
                pathname: ApplicationPaths.ViewItem,
                state: { item: item }
              }}
            >
              Avbryt
            </Button>
          </Grid>
        </Grid>
      </form>
      
      <SimpleDialog
        open={isDialogOpen}
        title={dialogTitle}
        content={dialogContent}
        cancelText={dialogCancelText}
        handleCancel={handleCloseDialog}
        confirmText={dialogConfirmText}
        handleConfirm={() => handleConfirmDelete(item)}
      />
    </div>
  );
}