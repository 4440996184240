import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { AppLayout, InfoLayout } from './components/layout/Layout';
import { Home } from './components/screens/Home';
import { Info } from './components/screens/Info';
import { AppHome } from './components/screens/AppHome';
import { Inventories } from './components/screens/Inventories';
import { Inventory } from './components/screens/Inventory';
import { AddItem } from './components/screens/AddItem';
import { ViewItem } from './components/screens/ViewItem';
import { EditItem } from './components/screens/EditItem';
import { FetchData } from './components/screens/FetchData';
import { Counter } from './components/screens/Counter';
import { Contacts } from './components/screens/Contacts';
import { SendInvite } from './components/screens/SendInvite';
import { Invites } from './components/screens/Invites';
import { FindItem } from './components/screens/FindItem';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths as ApiApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { ApplicationPaths } from './components/utils/AppConstants';
import { Anvandarvillkor } from './components/screens/Anvandarvillkor';
import { Integritetspolicy } from './components/screens/Integritetspolicy';
import { Organizations } from './components/screens/Organizations';
import { ViewMember } from './components/screens/ViewMember';
import { ClaimPublic } from './components/screens/ClaimPublic';
import { ClaimLoggedIn } from './components/screens/ClaimLoggedIn';
import { ViewAccessClaim } from './components/screens/ViewAccessClaim';


export default class App extends Component {
  static displayName = App.name;

  render() {
    //route structure adapted from https://dev.to/xavivzla/react-router-v5-multiple-layouts-4fo4
    return (
      <Switch>
        <Route path={ApiApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />        
        <AuthorizeRoute path='/app/'>
          <AppLayout>
            <Switch>
              <AuthorizeRoute path={ApplicationPaths.Inventories} exact component={Inventories} />
              <AuthorizeRoute path={ApplicationPaths.Inventory} exact component={Inventory} />
              <AuthorizeRoute path={ApplicationPaths.AddItem} exact component={AddItem} />
              <AuthorizeRoute path={ApplicationPaths.ViewItem} exact component={ViewItem} />
              <AuthorizeRoute path={ApplicationPaths.EditItem} exact component={EditItem} />
              <AuthorizeRoute path={ApplicationPaths.Contacts} exact component={Contacts} />
              <AuthorizeRoute path={ApplicationPaths.AddContact} exact component={SendInvite} />
              <AuthorizeRoute path={ApplicationPaths.Invites} exact component={Invites} />
              <AuthorizeRoute path={ApplicationPaths.Find} exact component={FindItem} />
              <AuthorizeRoute path={ApplicationPaths.Organizations} exact component={Organizations} />
              <AuthorizeRoute path={ApplicationPaths.AddMember} exact component={SendInvite} />
              <AuthorizeRoute path={ApplicationPaths.ViewMember} exact component={ViewMember} />
              <AuthorizeRoute path={ApplicationPaths.LoggedInClaimSection} component={ClaimLoggedIn} />
              <AuthorizeRoute path={ApplicationPaths.ViewAccessClaim} component={ViewAccessClaim} />
              <AuthorizeRoute path='/app/counter' exact component={Counter} />
              <AuthorizeRoute path='/app/fetch-data' exact component={FetchData} />
              <AuthorizeRoute path={ApplicationPaths.AppHome} exact component={AppHome} />
            </Switch>
          </AppLayout>
        </AuthorizeRoute> 
        <Route>
          <InfoLayout>
            <Switch>
              <Route exact path={ApplicationPaths.Home} component={Home} />
              <Route exact path={ApplicationPaths.Info} component={Info} />
              <Route exact path={ApplicationPaths.Anvandarvillkor} component={Anvandarvillkor} />
              <Route exact path={ApplicationPaths.Integritetspolicy} component={Integritetspolicy} />
              <Route path={ApplicationPaths.PublicClaimSection} component={ClaimPublic} />
            </Switch>
          </InfoLayout>
        </Route>
      </Switch>
    )
  }
}
