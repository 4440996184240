import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { ApplicationPaths } from '../utils/AppConstants';
import inventoryService from '../services/InventoryService';
import { Typography } from '@material-ui/core';
import InfoHeader from '../InfoHeader';
import { Redirect } from 'react-router-dom';
import SimpleCard, { CardData, ButtonData } from '../SimpleCard';

const useStyles = makeStyles((theme) => ({
  root: {
    //'margin-bottom':72 //height of bottom app bar
  },
  inventoriesGroup: {
    marginTop: theme.spacing(3),
  },
  inventoriesGrid: {
    marginTop: -4, //originally -12px
  },
}));

export function Inventories(props) {
  const classes = useStyles();

  const passedInOrganizationId = props.location && props.location.state ? props.location.state.organizationId : 0;

  const [ownInventories, setOwnInventories] = useState(null);
  const [ownInventoriesLoaded, setOwnInventoriesLoaded] = useState(false);
  const [sharedInventories, setSharedInventories] = useState(null);
  const [sharedInventoriesLoaded, setSharedInventoriesLoaded] = useState(false);

  //useEffect pattern adapted from https://stackoverflow.com/a/53219430/2768430  
  React.useEffect(() => {
    inventoryService.getOwnInventories().then(inventories => {
      setOwnInventories(inventories);
      setOwnInventoriesLoaded(true);
    });
    inventoryService.getSharedInventories().then(inventories => {
      setSharedInventories(inventories);
      setSharedInventoriesLoaded(true);
    })
  }, []);

  if (sharedInventoriesLoaded && passedInOrganizationId) {
    var organizationInventories = sharedInventories.filter(inventory => inventory.organizationId === passedInOrganizationId);
    if (organizationInventories.length === 1) {
      return <Redirect to={{ pathname: ApplicationPaths.Inventory, state: { inventory: organizationInventories[0] } }} />
    }
  }

  //if only one inventory, redirect to this immediately
  if (ownInventoriesLoaded && ownInventories.length === 1 && sharedInventoriesLoaded && sharedInventories.length === 0) {
    return <Redirect to={{ pathname: ApplicationPaths.Inventory, state: { inventory: ownInventories[0] } }} />
  }

  // "loading"-placeholders
  const loadingOwnPlh = ownInventoriesLoaded
    ? ""
    : (<p>Laddar...</p>);
  const loadingSharedPlh = sharedInventoriesLoaded
    ? ""
    : (<p>Laddar...</p>);

  const getCardsFromInventories = (inventoriesLoadedFlag, inventories) => {
    let result = "";
    if (!inventoriesLoadedFlag || !inventories || !inventories.length) {
      result = <></>;
    } else {
      const cards = inventories.map((inventory) =>
        new CardData(
          inventory.name,
          `${inventory.items ? inventory.items.length : 0} saker.`,
          [new ButtonData("Visa", { pathname: ApplicationPaths.Inventory, state: { inventory: inventory } })]
        ));
      result = <>
        {cards.map((cardData, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <SimpleCard cardData={cardData} />
          </Grid>
        ))}
      </>;
    }
    return result;
  }

  const inventoriesGridTmpl = (innerGrid) => (<Grid container spacing={3} className={classes.inventoriesGrid}> {innerGrid}</ Grid>)
  const ownInventoriesPlh = inventoriesGridTmpl(getCardsFromInventories(ownInventoriesLoaded, ownInventories));  
  const sharedInventoriesPlh = inventoriesGridTmpl(getCardsFromInventories(sharedInventoriesLoaded, sharedInventories));

  return (
    <main className={classes.root}>
      <InfoHeader heading="Förrådet" />

      <Box className={classes.inventoriesGroup}>
          <Typography variant="h2">
            Din låda
          </Typography>

        {loadingOwnPlh}

        {ownInventoriesPlh}        
      </Box>

      <Box className={classes.inventoriesGroup}>
          <Typography variant="h2">
            Delade lådor
          </Typography>

        {loadingSharedPlh}

        {sharedInventoriesPlh}

      </Box>
    </main>
  );
}