
export const InventoryActions = {
  Inventories: 'inventories',
  Inventory: 'inventory',
  AddItem: 'add-item',
  ViewItem: 'item',
  EditItem: 'edit-item',
};

export const ContactActions = {
  Contacts: 'contacts',
  AddContact: 'add-contact',
};

export const InviteActions = {
  Invites: 'invites'
};

export const FindActions = {
  Find: 'find'
};

export const OrgActions = {
  Organizations: 'organizations',
  AddMember: 'add-member',
  ViewMember: 'member',
  ViewAccessClaim: 'claim',
};

const prefix = '/app';

export const ApplicationPaths = {
  Home: '/',
  Info: '/info',
  Anvandarvillkor: '/anvandarvillkor',
  Integritetspolicy: '/integritetspolicy',
  AppHome: `${prefix}`,
  Inventories: `${prefix}/${InventoryActions.Inventories}`,
  Inventory: `${prefix}/${InventoryActions.Inventory}`,
  AddItem: `${prefix}/${InventoryActions.Inventory}/${InventoryActions.AddItem}`,
  ViewItem: `${prefix}/${InventoryActions.Inventory}/${InventoryActions.ViewItem}`,
  EditItem: `${prefix}/${InventoryActions.Inventory}/${InventoryActions.EditItem}`,
  Contacts: `${prefix}/${ContactActions.Contacts}`,
  AddContact: `${prefix}/${ContactActions.Contacts}/${ContactActions.AddContact}`,
  Invites: `${prefix}/${InviteActions.Invites}`,
  Find: `${prefix}/${FindActions.Find}`,
  Organizations: `${prefix}/${OrgActions.Organizations}`,
  AddMember: `${prefix}/${OrgActions.AddMember}`,
  ViewMember: `${prefix}/${OrgActions.ViewMember}`,
  PublicClaimSection: '/ansok',
  PublicClaimLink: (externalClaimIdentifier) => `${ApplicationPaths.PublicClaimSection}/${externalClaimIdentifier}`,
  LoggedInClaimSection: `${prefix}/ansok`,
  LoggedInClaimLink: (externalClaimIdentifier) => `${ApplicationPaths.LoggedInClaimSection}/${externalClaimIdentifier}`,
  ViewAccessClaim: `${prefix}/${OrgActions.ViewAccessClaim}`,
};

export const ApiEndpoints = {
  GetInventoriesExpanded: '/api/inventories/expanded',
  GetInventoryExpanded: (inventoryId) => 
    `/api/inventories/expanded/${inventoryId}`,
  GetSharedInventories: '/api/inventories/shared',
  UpdateInventories: (inventoryId) => 
    `/api/inventories/${inventoryId}`
  ,
  CreateItem: '/api/items',
  UpdateItem: '/api/items',
  DeleteItem: (itemId) =>
    `/api/items/${itemId}`,
  GetInvitesExpanded: '/api/invites/expanded',
  CreateInvite: '/api/invites',
  AcceptInvite: (inviteId) =>
    `/api/invites/accept/${inviteId}`,
  FindItemResult: (query) =>
    "/api/find?query=" + query,
  GetUserInfo: 'api/users/currentuserinfo',
  SendFeedbackMessage: 'api/feedback/message',
  GetOrgUserLinks: 'api/organizations/orguserlinks',
  GetOrganizationExpanded: (orgId) =>
    `/api/organizations/expanded/${orgId}`,
  DeleteOrgUserLink: (linkId) =>
    `/api/organizations/orguserlinks/${linkId}`,
  UpdateOrgUserLink: 'api/organizations/orguserlinks',
  CreateClaimLink: '/api/claims/claimlink',
  GetClaimLink: (externalClaimId) => `/api/claims/claimlink/${externalClaimId}`,
  GetClaimLinkOrganizationPublic: (externalClaimId) => `/api/publicclaims/claimlink/org/${externalClaimId}`,
  CreateAccessClaim: '/api/claims/accessclaim',
  AcceptAccessClaim: '/api/claims/accessclaim/accept',
  RefuseAccessClaim: (claimId) =>
    `/api/claims/accessclaim/${claimId}`,
};