import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Close as CloseIcon } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';
import { Button as SpacingEnabledButton } from '../CustomElements'
import { ApplicationPaths } from '../utils/AppConstants';
import organizationService from '../services/OrganizationService';
import { IconButton, Grid, Typography, Chip } from '@material-ui/core';
import InfoHeader from '../InfoHeader';
import SimpleDialog from '../SimpleDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.paper,
  },
  itemImg: {
    maxWidth: "100%",
  },
  widthConstraint: {
    maxWidth: "calc(100% - 48px)", //Leave room for icon close
  },
  chip: {
    textTransform: "uppercase", 
  }
}));

export function ViewMember(props) {
  //memberLink is OrgUserLinkDTO for viewed member. userLink is OrgUserLinkDTO for current user.
  const { userLink } = props.location.state;
  const classes = useStyles();
  const [memberLink, setMemberLink] = React.useState(props.location.state.memberLink);
  const [redirect, setRedirect] = React.useState(null);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const dialogTitle = "Bekräfta borttagning";
  const dialogContent = `Är du säker på att du vill ta bort ${memberLink.userName} från organisationen?`;
  const dialogCancelText = "Avbryt";
  const dialogConfirmText = "Ta bort";

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  }

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  }

  const handleConfirmDelete = (memberLink) => {
    setIsSubmitting(true);

    organizationService.deleteMember(memberLink)
      .then(response => {
        setIsSubmitting(false);
        setIsDialogOpen(false);
        redirectBack();
      });    
  }

  const memberIsAdmin = organizationService.isAdmin(memberLink);

  const redirectBack = () => {
    setRedirect({ pathname: ApplicationPaths.Organizations, state: { orgUserLink: userLink } });
  }

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  const demoteMember = (memberLink) => {
    organizationService.demoteMember(memberLink)
      .then(response => {
        //console.log(response);
        setMemberLink(response);
      });
  };

  const promoteMember = (memberLink) => {
    organizationService.promoteMember(memberLink)
      .then(response => {
        //console.log(response);
        setMemberLink(response);
      });
  };

  const deleteButton = organizationService.userCanDeleteMember(userLink, memberLink)
    ? <Button
        variant="outlined"
        disabled={isSubmitting}
        onClick={handleOpenDialog}
      >
        Ta bort
      </Button>
    : "";


  let promoteText = "Gör till Admin";
  if (memberIsAdmin) {
    promoteText = "Ta bort Admin";
  }

  const promoteButton = organizationService.userCanPromoteOrDemoteMember(userLink, memberLink)
    ? <SpacingEnabledButton
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        onClick={() => memberIsAdmin ? demoteMember(memberLink) : promoteMember(memberLink)}
        mr={2}
      >
        {promoteText}
      </SpacingEnabledButton>
    : "";


  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
      <Grid item container
        justify="space-between"
      >
        <Grid item className={classes.widthConstraint} >
          <InfoHeader heading={memberLink.userName} />
        </Grid>
        <Grid item>
          <IconButton aria-label="Avbryt" onClick={redirectBack} >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>


        {memberLink.userEmail ? <Grid item xs={12}><Typography variant="body1">{memberLink.userEmail}</Typography></Grid> : null}

        {organizationService.isAdmin(memberLink) ? <Grid item><Chip label="Admin" className={classes.chip} /></Grid> : null}
        {organizationService.isOwner(memberLink) ? <Grid item><Chip label="Owner" className={classes.chip} /></Grid> : null}

      <Grid item container
        justify="space-between"
      >
        <Grid item>
          {promoteButton}
          {deleteButton}
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"            
            disabled={isSubmitting}
            onClick={redirectBack}
          >
            Avbryt
          </Button>
        </Grid>
        </Grid>
      </Grid>

      <SimpleDialog
        open={isDialogOpen}
        title={dialogTitle}
        content={dialogContent}
        cancelText={dialogCancelText}
        handleCancel={handleCloseDialog}
        confirmText={dialogConfirmText}
        handleConfirm={() => handleConfirmDelete(memberLink)}
      />
    </div>
  );
}