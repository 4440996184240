import React from 'react';
import { Typography } from '@material-ui/core';

export function Integritetspolicy(props) {
  return (
    <main>
      <Typography variant="h2" component="h1" gutterBottom>Integritetspolicy</Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        INLEDNING – VAD ÄR PERSONUPPGIFTER? 
      </Typography>
      <Typography variant="body1" gutterBottom>
        När man använder Lånepools webbplats så överlämnas personuppgifter. Med ”personuppgifter” avses all slags information 
        som direkt eller indirekt kan identifieras med en fysisk person.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Lånepool är personuppgiftsansvarigt för de uppgifter som lämnas via, och behandlas hos lanepool.se.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Nedan finns en beskrivning av de riktlinjer som Lånepool tillämpar för personuppgiftsbehandling med mera. Ytterligare 
        riktlinjer kan gälla i särskilda fall. Riktlinjerna inskränker inte heller de rättigheter som finns enligt lag eller 
        annan rättsligt bindande bestämmelse, även kallat GDPR.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        ALLMÄNNA BESTÄMMELSER OM PERSONUPPGIFTER
      </Typography>
      <Typography variant="body1" gutterBottom>
        De allmänna bestämmelserna för personuppgiftsbehandlingen gäller för hela Lånepool.
      </Typography>
      <Typography variant="body1" gutterBottom>
        De allmänna bestämmelserna innebär att Lånepool alltid behandlar personuppgifter i enlighet med gällande 
        personuppgiftslagstiftning, med respekt för den personliga integriteten, all personuppgiftshantering vilar på en 
        laglig grund.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        INSAMLING AV PERSONUPPGIFTER
      </Typography>
      <Typography variant="body1" gutterBottom>
        Vid användande av Lånepools hemsida samlas endast information in som används för statistiska syften och besökaren 
        förblir anonym. Sådan information är tid för besöket, hur lång tid besöket varat samt vilka sidor som besökts. 
        Användaren kan även frivilligt lämna sina personuppgifter till Lånepool genom att registrera ett konto.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        ÄNDAMÅL
      </Typography>
      <Typography variant="body1" gutterBottom>
        Lånepool samlar bara in personuppgifter för vissa bestämda ändamål. Det allmänna ändamålet är vanligen att tillhandahålla 
        en tjänst som berörd person har begärt. Lånepool behandlar inte personuppgifterna för ändamål som är oförenliga med det 
        ursprungliga ändamålet. Närmare information om för vilket ändamål personuppgifterna används finns i samband med att 
        personuppgifterna lämnas, till exempel i anslutning till att ett konto upprättas. Informationen finns då i våra allmänna 
        villkor.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        SAMTYCKE
      </Typography>
      <Typography variant="body1" gutterBottom>
        Lånepool strävar efter att personuppgifter behandlas med den berörda personens samtycke. Ett lämnat samtycke kan alltid 
        återkallas. Om ett samtycke återkallas kommer Lånepool inte att behandla personuppgifterna ytterligare och inte heller 
        uppdatera tidigare lämnade uppgifter. För de fall ett samtycke återkallas avslutas behandlingen omedelbart. Det ska dock 
        noteras att trots återkallat samtycke kan viss behandling kan vara nödvändig för att fullfölja lag eller avtal.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        ÖVERLÄMNANDE AV PERSONUPPGIFTER
      </Typography>
      <Typography variant="body1" gutterBottom>
        Personuppgifter kan komma att överlämnas till samarbetspartner, till exempel för att utföra de uppgifter berörd person 
        har begärt. Lista på samarbetspartners finns i allmänna villkor. I de fall vi överför dina uppgifter till ett annat företag 
        som behandlar dina personuppgifter på vårt uppdrag i egenskap av ett personuppgiftsbiträde har vi biträdesavtal på plats 
        med strikta instruktioner om behandlingen av personuppgifter, konfidentialitet och säkerhet.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        SÄKERHET
      </Typography>
      <Typography variant="body1" gutterBottom>
        Lånepool vidtar tekniska och organisatoriska åtgärder för att skydda de personuppgifter som behandlas, så att uppgifterna inte 
        förloras, förstörs, manipuleras eller blir tillgängliga för obehöriga. Åtgärderna syftar till att åstadkomma en 
        säkerhetsnivå som är tillräckligt hög med hänsyn till de tekniska möjligheter som finns att tillgå.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        RÄTT ATT FÅ INFORMATION OCH RÄTTELSE
      </Typography>
      <Typography variant="body1" gutterBottom>
        Var och en har rätt att efter begäran få reda på i vilken utsträckning uppgifter om den egna personen behandlas av Lånepool. Om 
        sådana personuppgifter finns hos Lånepool får berörd person efter begärd information om vilka personuppgifter som behandlas, 
        varifrån dessa har inhämtats, för vilket eller vilka ändamål behandlingen sker och till vem eller vilka uppgifterna lämnas ut. 
        Begäran om information rörande personuppgiftsbehandlingen sker till info@lanepool.se
      </Typography>
      <Typography variant="body1" gutterBottom>
        Var och en har rätt att begära rättelse, blockering eller radering av egna personuppgifter som är felaktiga eller som behandlas 
        i strid med gällande rätt. Lånepool är då skyldiga att vidta sådana åtgärder.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        ÄNDRINGAR AV PERSONUPPGIFTSHANTERING
      </Typography>
      <Typography variant="body1" gutterBottom>
        Om det sker förändringar i hur Lånepool hanterar personuppgifter kommer det att meddelas på denna sida. Rutiner för behandling kan 
        t ex komma att ändras om lagstiftningen ändras.
      </Typography>

      <Typography variant="h4" component="h2" gutterBottom>
        KONTAKT – PERSONUPPGIFTSOMBUD
      </Typography>
      <Typography variant="body1" gutterBottom>
        Har du frågor eller invändningar om hur vi hanterar dina personuppgifter, vänligen kontakta:
      </Typography>
      <Typography variant="body1" gutterBottom>
        Personuppgiftsombud: Per Jungnelius <br />
        E-mail: info@lanepool.se
      </Typography>


      <Typography variant="h4" component="h2" gutterBottom>
        COOKIES
      </Typography>
      <Typography variant="body1" gutterBottom>
        En cookie är en liten textfil som webbplatsen du besöker begär att få spara på din dator. Du bestämmer själv hur din dator 
        hanterar cookies genom inställningarna i din webbläsare.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Vi tar hjälp av både förstaparts- och tredjepartscookies för olika ändamål. En del av de cookies som läggs på din dator raderas då 
        du lämnar vår webbplats (sessionscookies) medan andra ligger kvar i upp till 2 år på din dator för att webbplatsen ska kunna känna 
        igen dig från gång till gång om du återkommer (permanenta cookies).
      </Typography>
      <Typography variant="body1" gutterBottom>
        Vi använder cookies på vår webbplats för att:
      </Typography>
      <Typography variant="body1" gutterBottom>
        Underlätta användningen av webbplatsen och göra så att sidorna ska laddas snabbt på din dator. (Förstaparts sessionscookie)
      </Typography>
      <Typography variant="body1" gutterBottom>
        Med hjälp av analysverktygen Google Analytics (tredjeparts sessions- och permanenta cookies) samla in statistik om hur våra besökare 
        rör sig på webbplatsen, till exempel:
      </Typography>
      <Typography variant="body1" gutterBottom>
        Om besökaren har varit på webbplatsen tidigare<br />
        Varifrån besökaren har klickat sig in till vår webbplats<br />
        Vilka sidor som besöks på webbplatsen<br />
        Hur lång tid besöket tar<br />
        Vilken webbläsare som används<br />
      </Typography>
      <Typography variant="body1" gutterBottom>
        Har du frågor om cookies på vår webbplats är du välkommen att kontakt oss på info@lanepool.se.
      </Typography>

    </main>
  );
}
