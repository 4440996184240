import { ApiEndpoints } from '../utils/AppConstants';

export class FeedbackService {  

  async sendMessage(message, userEmail) {

    const feedbackDTO = {
      message: message,
      userEmail: userEmail,
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(feedbackDTO)
    };

    const response = await fetch(ApiEndpoints.SendFeedbackMessage, requestOptions);

    return response;
  }

}

const feedbackService = new FeedbackService();

export default feedbackService;