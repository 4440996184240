
//TODO! Begun implementing editable list adapted from https://codesandbox.io/s/l7v0qz869z?file=/src/index.js:0-169
import React, { useState } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import { Image as ImageIcon } from '@material-ui/icons';
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from '@material-ui/core/Avatar';
import DragHandleIcon from "@material-ui/icons/DragHandle";
import authService from './api-authorization/AuthorizeService'
import { ApiEndpoints } from './utils/AppConstants';
import { Grid, Typography } from "@material-ui/core";


const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <DragHandleIcon />
  </ListItemIcon>
));

const SortableItem = SortableElement(({ text, imageUrl }) => (
  <ListItem ContainerComponent="div" disableGutters={true}>
    <ListItemAvatar>
      {imageUrl
        ? <Avatar alt={text} src={imageUrl} />
        : <Avatar><ImageIcon /></Avatar>
      }
    </ListItemAvatar>
    <ListItemText primary={text} />
    <ListItemSecondaryAction>
      <DragHandle />
    </ListItemSecondaryAction>
  </ListItem>
));

const SortableListContainer = SortableContainer(({ items }) => (
  <List component="div">
    {items.map(({ itemId, name, imageUrl }, index) => (
      <SortableItem key={itemId} index={index} text={name} imageUrl={imageUrl} />
    ))}
  </List>
));

export default function EditableList(props) {

  const { inventory, onSaveCallback, onCancel} = props;
  const [editableItems, setEditableItems] = useState(inventory.items);
  const [isSaving, setIsSaving] = useState(false);

  //adapted from https://stackoverflow.com/a/6470794/2768430 to not mutate original array and return new reordered array
  function arraymove(arr, fromIndex, toIndex) {
    let arrayCopy = arr.slice();
    var element = arrayCopy[fromIndex];
    arrayCopy.splice(fromIndex, 1);
    arrayCopy.splice(toIndex, 0, element);
    return arrayCopy;
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {    
    setEditableItems(arraymove(editableItems, oldIndex, newIndex));
  };

  const handleOnSave = (event) => {
    event.preventDefault();
    setIsSaving(true);

    //update object
    inventory.items = editableItems;
    //update sortorder to reflect new order (NOTE: Is this the best place to do this?)
    for (let i = 0; i < inventory.items.length; i++) {
      inventory.items[i].sortOrder = i;
    }

    authService.getAccessToken().then(token => {
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(inventory)
      };
      const url = ApiEndpoints.UpdateInventories(inventory.inventoryId);
      fetch(url, requestOptions)
        .then(response => {
          console.log(response);
          setIsSaving(false);
          onSaveCallback(editableItems);
        });
    });
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SortableListContainer
          items={editableItems}
          onSortEnd={onSortEnd}
          useDragHandle={true}
          lockAxis="y"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          Du kan sortera raderna genom att dra och släppa i handtagen.
      </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          onClick={handleOnSave}
          disabled={isSaving}
        >
          Spara ändringar
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          onClick={onCancel}
          disabled={isSaving}
        >
          Avbryt
        </Button>
      </Grid>
    </Grid>
  );
}