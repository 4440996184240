import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import SimpleCard from '../SimpleCard'
import { ApplicationPaths } from '../utils/AppConstants';
import SimpleList, { ListItemData } from '../SimpleList';
import InventoryContactsBar from '../InventoryContactsBar';
import inventoryService from '../services/InventoryService';
import { Redirect } from 'react-router-dom';
import InfoHeader from '../InfoHeader';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    'margin-bottom':72 //height of bottom app bar
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(5),
    right: theme.spacing(2),

    '&::before': {
      content: '""',
      position: 'absolute',
      padding: 32,
      'border-radius': '50%',
      background: '#fff',
      'z-index': -1,
    },
  },
  contentGrid: {
    //marginTop: theme.spacing(2),
  },
  invitesGroup: {
    marginTop: theme.spacing(3),
  }
}));

export function Contacts(props) {
  const classes = useStyles();  

  const passedInInventory = props.location && props.location.state ? props.location.state.inventory : null;
  const passedInInventoryId = props.location && props.location.state ? props.location.state.inventoryId : 0;  

  const [inventory, setInventory] = useState(passedInInventory ?? {});
  const [inventoryLoaded, setInventoryLoaded] = useState(!!passedInInventory);

  const cards = [] //TODO: not really helping as it is. cardInfoService.getContactsCards(userInfo, inventory);

  //useEffect pattern adapted from https://stackoverflow.com/a/53219430/2768430  
  React.useEffect(() => {
    if (passedInInventoryId && !inventoryLoaded) {
      inventoryService.getInventory(passedInInventoryId).then(inventory => {
        setInventory(inventory);
        setInventoryLoaded(true);
      })
    }
  }, [inventoryLoaded, passedInInventoryId]);


  if (!passedInInventory && !passedInInventoryId) {
    return <Redirect to={ApplicationPaths.Inventories} />
  }

  //Safety: if inventory is organizational or user is not admin, redirect to inventories start page
  if (!inventoryService.isPersonalAdministered(inventory)) {
    return <Redirect to={ApplicationPaths.Inventories} />
  }

  const addContactLinkTo = inventoryLoaded
    ? {
      pathname: ApplicationPaths.AddContact,
      state: { inventoryId: inventory.inventoryId }
    }
    : null;

  const list = inventory && inventory.users
    ? (
      <Box>        
        <SimpleList
          loading={!inventoryLoaded}
          items={(inventory.users ?? []).map(user => new ListItemData(null, user.name, user.email, null))}
          emptyListText="Du har inga kontakter tillagda ännu."
          addButtonText="Bjud in kontakt"
          addButtonLinkTo={addContactLinkTo}
        />        
      </Box>
    )
    : "";

  const invitedList = inventory && inventory.invites && inventory.invites.length
    ? (<Box className={classes.invitesGroup} >
      <Typography variant="h2">Inbjudningar</Typography>
      <SimpleList items={inventory.invites.map((invite, index) => new ListItemData(index, invite.email, invite.email, null))} />
    </Box>)
    : "";

  return (
    <main className={classes.root}>
      <InfoHeader heading="Kontakter" />

      <Grid container spacing={3} className={classes.contentGrid}>
        <Grid item xs={12} sm={6}>
          {list}          

          {invitedList}
        </Grid>

        <Grid item xs={12} sm={6}>
          {cards.map((cardData, index) => (
            <SimpleCard cardData={cardData} key={index} />
          ))}
        </Grid>
      </Grid>

      <InventoryContactsBar
        value={ApplicationPaths.Contacts}
        //fabTo={addContactLinkTo} Remove fab until it can complement regular add button better. Perhaps by being visible when regular button is out of screen
        inventory={inventory} />
    </main>
  );
}