import authService from '../api-authorization/AuthorizeService'
import { ApiEndpoints } from '../utils/AppConstants';

export class OrganizationService {

  isAdmin(orgUserLink) {
    if (!orgUserLink) {
      return false;
    }
    return orgUserLink.accessType >= 1;
  }

  isOwner(orgUserLink) {
    if (!orgUserLink) {
      return false;
    }
    return orgUserLink.accessType >= 2;
  }

  userCanDeleteMember(orgUserLink, orgMemberLink) {
    if (orgUserLink.organizationId !== orgMemberLink.organizationId) {
      return false;
    }
    if (this.isOwner(orgMemberLink)) {
      return false;
    }
    return orgUserLink.accessType > orgMemberLink.accessType;
  }

  userCanPromoteOrDemoteMember(orgUserLink, orgMemberLink) {
    if (orgUserLink.organizationId !== orgMemberLink.organizationId) {
      return false;
    }
    if (this.isOwner(orgMemberLink)) {
      return false;
    }
    if (!this.isOwner(orgUserLink)) {
      return false;
    }
    return true;
  }

  filterAdminLinks(orgUserLinks) {
    if (!orgUserLinks) {
      return orgUserLinks;
    }
    return orgUserLinks.filter((link) => this.isAdmin(link));
  }  

  hasAdminLinks(orgUserLinks) {
    if (!orgUserLinks) {
      return false;
    }
    return orgUserLinks.some((link) => this.isAdmin(link));
  } 

  async getOrgUserLinks() {
    const token = await authService.getAccessToken();
    const results = await fetch(ApiEndpoints.GetOrgUserLinks, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    const orgUserLinks = await results.json();
    return orgUserLinks;
  }  

  async getOrganizationExpanded(orgId) {
    const token = await authService.getAccessToken();
    const results = await fetch(ApiEndpoints.GetOrganizationExpanded(orgId), {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    const orgExpanded = await results.json();
    return orgExpanded;
  }  

  async deleteMember(memberLink) {
    authService.getAccessToken().then(token => {
      const requestOptions = {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      };
      
      return fetch(ApiEndpoints.DeleteOrgUserLink(memberLink.linkId), requestOptions)
        .then(response => {
          console.log(response);
        })
    })
  }

  async _updateOrgUserLink(orgUserLinkDTO) {

    const token = await authService.getAccessToken();

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(orgUserLinkDTO)
    };

    const response = await fetch(ApiEndpoints.UpdateOrgUserLink, requestOptions)
      .then(response => {
        if (!response.ok) {
          console.error(response);
          throw new Error('Network response was not ok');
        }
        return response.json();
      });

    return response;    
  }

  async demoteMember(memberLink) {
    memberLink.accessType--;
    return await this._updateOrgUserLink(memberLink);
  }

  async promoteMember(memberLink) {
    memberLink.accessType++;
    return await this._updateOrgUserLink(memberLink);
  }



}

const organizationService = new OrganizationService();

export default organizationService;