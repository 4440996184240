import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ApplicationPaths } from '../utils/AppConstants';
import organizationService from '../services/OrganizationService';
import InfoHeader from '../InfoHeader';
import { Link } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Star as StarIcon, StarBorder as StarBorderIcon } from '@material-ui/icons';
import SimpleList, { ListItemData } from '../SimpleList';
import claimsService from '../services/ClaimsService';
import { ClaimLinkDTO } from '../utils/DTOs';

const useStyles = makeStyles((theme) => ({
  root: {

  },
  contentGrid: {
    //marginTop: theme.spacing(2),
  },
  iconStar: {
    color: theme.palette.accent.main,
  },
  invitesGroup: {
    marginTop: theme.spacing(3),
  },
  claimsSection: {
    marginTop: theme.spacing(3),
  },
  accessClaimsSection: {
    marginTop: theme.spacing(3),
  },
}));

export function Organizations(props) {
  const classes = useStyles();  

  //const passedInOrganization = props.location && props.location.state ? props.location.state.organization : null;

  const [currentOrgUserLink, setCurrentOrgUserLink] = useState(props.location && props.location.state ? props.location.state.orgUserLink : null);  
  const [organization, setOrganization] = useState(null);
  const [claimLink, setClaimLink] = useState(organization ? organization.claimLink : null);
  const [organizationLoaded, setOrganizationLoaded] = useState(false);
  const [adminOrgUserLinks, setAdminOrgUserLinks] = useState(null);
  const [isClaimLinkButtonSubmitting, setIsClaimLinkButtonSubmitting] = React.useState(false);

  //useEffect pattern adapted from https://stackoverflow.com/a/53219430/2768430  
  React.useEffect(() => {
    if (!organizationLoaded) {
      if (currentOrgUserLink) {
        //no org loaded but passed in org id -> load org
        organizationService.getOrganizationExpanded(currentOrgUserLink.organizationId).then(organization => {
          setOrganization(organization);
          setClaimLink(organization ? organization.claimLink : null)
          setOrganizationLoaded(true);
        });
      } else {
        //no org loaded and no passed in org id -> load possible org links
        organizationService.getOrgUserLinks().then(orgUserLinks => {
          const adminOrgUserLinks = organizationService.filterAdminLinks(orgUserLinks);
          setAdminOrgUserLinks(adminOrgUserLinks);
          if (adminOrgUserLinks.length === 1) {
            setCurrentOrgUserLink(adminOrgUserLinks[0]);
          }
        })
      }
    }
  }, [organizationLoaded, currentOrgUserLink]);

  const handleAddClaimLink = () => {
    setIsClaimLinkButtonSubmitting(true);
    const claimLinkDTO = ClaimLinkDTO.fromObj({ organizationId: organization.organizationId });
    claimsService.createClaimLink(claimLinkDTO).then(claimLinkDTO => {
      //we now have the returned claimLinkDTO. Update the organization and set claimLink.
      organization.claimLink = claimLinkDTO;
      setClaimLink(claimLinkDTO);
      setIsClaimLinkButtonSubmitting(false);
    });
  };

  let headingText = "";
  let content = null;

  if (!organizationLoaded) {
    if (!adminOrgUserLinks || currentOrgUserLink) {
      //either we have just arrived and are loading orgUserLinks or we have loaded them and passed in an orgUserLink which hasn't yet been loaded.
      content = <p>Laddar...</p>;
    } else if (adminOrgUserLinks.length === 0) {
      headingText = "Ojdå!";
      content = <p>Det finns inga organisationer att visa. Du har antagligen hamnat på den här sidan av misstag.</p>;
    } else {
      headingText = "Välj";
      content = (<ul>
        {adminOrgUserLinks.map((link, index) => (
          <li key={link.linkId}><Link to={{ path: ApplicationPaths.Organizations, state: { orgUserLink: link } }}>{link.organizationName}</Link></li>
        ))}
      </ul>);
    }
  } else {
    headingText = organization.name;

    const addMemberLinkTo = {
      pathname: ApplicationPaths.AddMember,
      state: { organizationId: organization.organizationId }
    };

    const memberList = (
      <Box>
        <Typography variant="h2">Medlemmar</Typography>
        <SimpleList
          items={(organization.users ?? []).map(member => new ListItemData(member.userId, member.userName, member.userEmail, {
            pathname: ApplicationPaths.ViewMember,
            state: { memberLink: member, userLink: currentOrgUserLink }
          }, null, !organizationService.isAdmin(member) ? null : organizationService.isOwner(member) ? <StarIcon className={classes.iconStar} /> : <StarBorderIcon className={classes.iconStar} />))}
          emptyListText="Det finns inga medlemmar tillagda ännu."
          addButtonText="Bjud in medlem"
          addButtonLinkTo={addMemberLinkTo}
        />
      </Box>
    );

    const invitedList = organization.invites && organization.invites.length
      ? (<Box className={classes.invitesGroup} >
        <Typography variant="h2">Inbjudningar</Typography>
        <SimpleList items={organization.invites.map((invite, index) => new ListItemData(index, invite.email, invite.email, null))} />
      </Box>)
      : "";

    let claimLinkLink = null;
    let claimLinkButton = null;
    if (claimLink && claimLink.externalId) {
      var claimLinkUrl = `${window.location.origin}${ApplicationPaths.PublicClaimLink(claimLink.externalId)}`;
      claimLinkLink = <a href={claimLinkUrl}>{claimLinkUrl}</a>
    } else {
      claimLinkButton = <Button
        variant="contained"
        color="primary"
        onClick={handleAddClaimLink}
        disabled={isClaimLinkButtonSubmitting}
      >
        Skapa ansökningslänk
                </Button>
    }

    const claimsSection = <Box className={classes.claimsSection} >
      <Typography variant="h2">Ansökningar</Typography>
      <Typography variant="body1">Via en ansökningslänk kan en användare ansöka om att bli tillagd i organisationen. En administratör måste sen godkänna att användaren ska läggas till i organisationen.</Typography>
      {claimLinkLink}
      {claimLinkButton}
    </Box>

    const accessClaimsList = organization.accessClaims && organization.accessClaims.length
      ? (<Box className={classes.accessClaimsSection} >
        <Typography variant="h2">Aktiva ansökningar</Typography>
        <SimpleList items={organization.accessClaims.map((accessClaim, index) => new ListItemData(index, accessClaim.userName, accessClaim.userEmail, {
          pathname: ApplicationPaths.ViewAccessClaim,
          state: { accessClaim: accessClaim, userLink: currentOrgUserLink }
        }))} />
      </Box>)
      : "";

    content = (
      <Grid container spacing={3} className={classes.contentGrid}>
        <Grid item xs={12} sm={6}>
          
          {memberList}

          {invitedList}

          {claimsSection}

          {accessClaimsList}
        </Grid>
      </Grid>
    );
  }

  return (
    <main className={classes.root}>
      <InfoHeader heading={headingText} />

      {content}
    </main>
  );
}