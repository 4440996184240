import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Close as CloseIcon } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';
import { Button as SpacingEnabledButton } from '../CustomElements'
import { ApplicationPaths } from '../utils/AppConstants';
import organizationService from '../services/OrganizationService';
import { IconButton, Grid, Typography } from '@material-ui/core';
import InfoHeader from '../InfoHeader';
import SimpleDialog from '../SimpleDialog';
import claimsService from '../services/ClaimsService';

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.paper,
  },
  itemImg: {
    maxWidth: "100%",
  },
  widthConstraint: {
    maxWidth: "calc(100% - 48px)", //Leave room for icon close
  },
}));

export function ViewAccessClaim(props) {
  //memberLink is OrgUserLinkDTO for viewed member. userLink is OrgUserLinkDTO for current user.
  const { userLink } = props.location.state;
  const classes = useStyles();
  const [accessClaim] = React.useState(props.location.state.accessClaim);
  const [redirect, setRedirect] = React.useState(null);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const dialogTitle = "Bekräfta avslag";
  const dialogContent = `Är du säker på att du vill avslå ${accessClaim.userName}s begäran att bli medlem i ${accessClaim.organizationName}?`;
  const dialogCancelText = "Avbryt";
  const dialogConfirmText = "Avslå";

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  }

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  }

  const handleConfirmRefusal = (accessClaim) => {
    setIsSubmitting(true);

    claimsService.refuseClaim(accessClaim)
      .then(response => {
        setIsSubmitting(false);
        setIsDialogOpen(false);
        redirectBack();
      });    
  }

  const redirectBack = () => {
    setRedirect({ pathname: ApplicationPaths.Organizations, state: { orgUserLink: userLink } });
  }

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  const acceptClaim = (accessClaim) => {
    setIsSubmitting(true);

    claimsService.acceptClaim(accessClaim)
      .then(response => {
        //console.log(response);
        setIsSubmitting(false);
        redirectBack();
      });
  };

  const refuseButton = organizationService.isAdmin(userLink)
    ? <Button
        variant="outlined"
        disabled={isSubmitting}
        onClick={handleOpenDialog}
      >
        Avslå
      </Button>
    : "";

  const acceptButton = organizationService.isAdmin(userLink)
    ? <SpacingEnabledButton
        variant="contained"
        color="primary"
      disabled={isSubmitting}
      onClick={() => acceptClaim(accessClaim)}
        mr={2}
      >
        Godkänn
      </SpacingEnabledButton>
    : "";


  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item container
          justify="space-between"
        >
          <Grid item className={classes.widthConstraint} >
            <InfoHeader heading={accessClaim.userName} />
          </Grid>
          <Grid item>
            <IconButton aria-label="Avbryt" onClick={redirectBack} >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>


        {accessClaim.userEmail ? <Grid item xs={12}><Typography variant="body1">{accessClaim.userEmail}</Typography></Grid> : null}

        <Grid item xs={12}><Typography variant="body1">Vill ansluta sig till {accessClaim.organizationName}.</Typography></Grid>

        <Grid item container
          justify="space-between"
        >
           <Grid item>
            {acceptButton}
            {refuseButton}
          </Grid> 
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={isSubmitting}
              onClick={redirectBack}
            >
              Avbryt
          </Button>
          </Grid>
        </Grid>
      </Grid>

      <SimpleDialog
        open={isDialogOpen}
        title={dialogTitle}
        content={dialogContent}
        cancelText={dialogCancelText}
        handleCancel={handleCloseDialog}
        confirmText={dialogConfirmText}
        handleConfirm={() => handleConfirmRefusal(accessClaim)}
      />
    </div>
  );
}