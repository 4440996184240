import authService from '../api-authorization/AuthorizeService'
import { ApiEndpoints } from '../utils/AppConstants';

export class InvitesService {

  async getInvites() {
    const token = await authService.getAccessToken();
    const results = await fetch(ApiEndpoints.GetInvitesExpanded, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    const invites = await results.json();

    return invites;
  }

  async acceptInvite(inviteId) {
    const token = await authService.getAccessToken();
    await fetch(ApiEndpoints.AcceptInvite(inviteId), {
      method: 'POST',
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    return;
  }

  async sendInvite(inviteDTO) {
    const token = await authService.getAccessToken();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(inviteDTO)
    };

    const response = await fetch(ApiEndpoints.CreateInvite, requestOptions)
      .then(response => {
        if (!response.ok) {
          console.error(response);
          throw new Error('Network response was not ok');
        }
        return response.json();
      });

    return response;
  }

}

const invitesService = new InvitesService();

export default invitesService;