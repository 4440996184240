import authService from '../api-authorization/AuthorizeService'
import { ApiEndpoints } from '../utils/AppConstants';

export class UserService {

  async getUserInfo() {
    const token = await authService.getAccessToken();

    const response = await fetch(ApiEndpoints.GetUserInfo, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    if (response.status === 401) {
      //TODO: Handle this somehow.
      console.warn("401 from " + ApiEndpoints.GetUserInfo);
      return;
    }

    const data = await response.json();    
    return data;
  }

}

const userService = new UserService();

export default userService;