//import React, { Component } from 'react';
import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import SimpleCard from '../SimpleCard';
import { UserContext } from '../utils/AppContexts';
import { Typography } from '@material-ui/core';
import cardInfoService from '../services/CardInfoService';




export function AppHome(props) {
  const userInfo = useContext(UserContext);

  const cards = cardInfoService.getAppHomeCards(userInfo);

  return (
    <main>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">Välkommen {userInfo.firstName}</Typography>
        </Grid>

        {cards.map((cardData, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <SimpleCard cardData={cardData} equalizeHeight={true} />
          </Grid>
        ))}
      </Grid>
    </main>
  );
}

