import { ButtonData, CardData } from '../SimpleCard';
import { ApplicationPaths } from '../utils/AppConstants';

function CardInfoService() {

  const getAppHomeCards = (userinfo) => {
    let cards = [];

    if (userinfo.contactsInfo.inviteCount) {
      var invitesCard = new CardData(
        "Du är inbjuden!",
        `Du har ${userinfo.contactsInfo.inviteCount} ny inbjudan till en utlåningslåda.`,
        [new ButtonData("Till inbjudan", ApplicationPaths.Invites)]
      )
      cards.push(invitesCard);
    }

    if (userinfo.accessClaims && userinfo.accessClaims.length) {
      var title = "Din ansökan behandlas!";
      var organizarionNamesArray = userinfo.accessClaims.map((p) => p.organizationName);
      var namesArrayFormatted = _stringArrayToString(organizarionNamesArray);
      var text = `Du har ansökt till ${namesArrayFormatted}. När din ansökan godkänts hittar du lånepoolen under "Förrådet".`;
      var accessClaimsCard = new CardData(
        title,
        text,
        [new ButtonData("Till Förrådet", ApplicationPaths.Inventories)]
      );
      cards.push(accessClaimsCard);
    }
    
    const yourToolboxCardInfoText = userinfo.inventoryInfo.toolCount
      ? `Du har ${userinfo.inventoryInfo.toolCount} prylar i din utlåningslåda`
      : "Du har ännu inga prylar i din utlåningslåda";
    var yourToolboxCard = new CardData(
      "Din utlåningslåda",
      yourToolboxCardInfoText,
      [new ButtonData("Lägg till", ApplicationPaths.Inventories)]); //TODO: Fixa denna länk så den går till användarens inventory direkt.
    cards.push(yourToolboxCard);

    const borrowToolsCardInfo = userinfo.contactsInfo.toolCount
      ? `Behöver du en pryl du inte har? Kolla bland dina kontakters ${userinfo.contactsInfo.toolCount} prylar.`
      : "Du har ännu inte blivit inbjuden att dela någon utlåningslåda. Tipsa en vän om att dela med dig!";
    const borrowToolsCard = new CardData(
      "Låna pryl",
      borrowToolsCardInfo,
      [new ButtonData("Sök pryl", ApplicationPaths.Find)]);
    cards.push(borrowToolsCard);

    const shareYourToolboxCard = new CardData(
      "Dela med dig",
      "Dela mera! Dela din utlåningslåda med en vän genom att skicka en inbjudan",
      [new ButtonData("Dela", ApplicationPaths.Contacts)]);
    cards.push(shareYourToolboxCard);

    return cards;
  };

  const getContactsCards = (userinfo, inventory) => {
    let cards = [];

    cards.push(_getShareYourToolboxCard(userinfo));

    return cards;
  };

  const getPersonalOwnedInventoryCards = (userinfo, inventory) => {
    let cards = [];
    
    cards.push(_getShareYourToolboxCard(userinfo, inventory));

    return cards;
  };

  const _getShareYourToolboxCard = (userinfo, inventory) => {
    const shareYourToolboxCardInfoText = userinfo.inventoryInfo.contactCount
      ? `Du delar din utlåningslåda med ${userinfo.inventoryInfo.contactCount} kontakter. Vill du dela med flera?`
      : "Du delar ännu inte din utlåningslåda med någon. Sharing is caring ;)!";
    var shareYourToolboxCard = new CardData("Dela med dig", shareYourToolboxCardInfoText, [new ButtonData("Dela", { pathname: ApplicationPaths.Contacts, state: { inventory: inventory } })]);
    return shareYourToolboxCard;
  }

  //adapted from https://stackoverflow.com/a/53879103
  const _stringArrayToString = (arr) => {
    if (arr.length === 1) return arr[0];
    const firsts = arr.slice(0, arr.length - 1);
    const last = arr[arr.length - 1];
    return firsts.join(', ') + ' och ' + last;
  }

  return {
    getAppHomeCards: getAppHomeCards,
    getContactsCards: getContactsCards,
    getPersonalOwnedInventoryCards: getPersonalOwnedInventoryCards,
  }
}

const cardInfoService = new CardInfoService();

export default cardInfoService;