import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SimpleCard from '../SimpleCard'
import { ApplicationPaths } from '../utils/AppConstants';
import SimpleList, { ListItemData } from '../SimpleList';
import EditableList from '../EditableList';
import InventoryContactsBar from '../InventoryContactsBar';
import inventoryService from '../services/InventoryService';
import { UserContext } from '../utils/AppContexts';
import cardInfoService from '../services/CardInfoService';
import InfoHeader from '../InfoHeader';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    'margin-bottom':72 //height of bottom app bar
  },
  contentContainer: {
    paddingBottom: theme.spacing(1),
  }
}));

export function Inventory(props) {
  const classes = useStyles();
  const userInfo = useContext(UserContext);

  const passedInInventory = props.location && props.location.state ? props.location.state.inventory : null;
  const passedInInventoryId = props.location && props.location.state ? props.location.state.inventoryId : 0;  

  const [inventory, setInventory] = useState(passedInInventory ?? {});
  const [inventoryLoaded, setInventoryLoaded] = useState(!!passedInInventory);
  const [editmodeActive, setEditmodeActive] = useState(false);

  //useEffect pattern adapted from https://stackoverflow.com/a/53219430/2768430  
  React.useEffect(() => {
    if (passedInInventoryId && !inventoryLoaded) {      
      inventoryService.getInventory(passedInInventoryId).then(inventory => {
        setInventory(inventory);
        setInventoryLoaded(true);
      })
    }
  }, [inventoryLoaded, passedInInventoryId]);

  if (!passedInInventory && !passedInInventoryId) {
    return <Redirect to={ApplicationPaths.Inventories} />
  }

  const cards = inventoryService.isPersonalAdministered(inventory)
    ? cardInfoService.getPersonalOwnedInventoryCards(userInfo, inventory)
    : [];

  const handleEditmodeActive = function (event) {
    event.preventDefault();
    setEditmodeActive(true);
  }
  const handleCancelEditmode = function (event) {
    event.preventDefault();
    setEditmodeActive(false);
  }
  const handleSaveInventory = function (items) {
    setEditmodeActive(false);
  } 

  const addItemLinkTo = inventoryLoaded
    ? {
      pathname: ApplicationPaths.AddItem,
      state: { inventoryId: inventory.inventoryId }
    }
    : null;

  const inventoryContactsBar = inventoryLoaded && inventoryService.isPersonalAdministered(inventory)
    ? <InventoryContactsBar
      value={ApplicationPaths.Inventory}
      //fabTo={addItemLinkTo} Remove fab until it can complement regular add button better. Perhaps by being visible when regular button is out of screen
      inventory={inventory} />
    : null;

  const list = editmodeActive
    ? <EditableList inventory={inventory} onSaveCallback={handleSaveInventory} onCancel={handleCancelEditmode} />
    : <SimpleList
      loading={!inventoryLoaded}
      items={(inventory.items ?? []).map(item => new ListItemData(item.itemId, item.name, item.imageUrl, {
        pathname: ApplicationPaths.ViewItem,
        state: { item: item, isEditable: inventoryService.isAdminOrOwner(inventory) }
      }))}
      editMinItems={2}
      emptyListText="Du har inga saker tillagda ännu."
      addButtonText="Lägg till"
      addButtonLinkTo={inventoryService.isAdminOrOwner(inventory) ? addItemLinkTo : null }
      onActivateEdit={inventoryService.isAdminOrOwner(inventory) ? handleEditmodeActive : null } />;

  return (
    <main className={classes.root}>
      <InfoHeader heading={inventory.name} />

      <Grid container spacing={3} className={classes.contentContainer}>
        <Grid item xs={12} sm={6}>
          {list}
        </Grid>

        <Grid item xs={12} sm={6}>
          {cards.map((cardData, index) => (
            <SimpleCard cardData={cardData} key={index} />
          ))}
        </Grid>
      </Grid>

      {inventoryContactsBar}
    </main>
  );
}