
export class ItemDTO {
  constructor(itemId, sortOrder, name, imageUrl, description, inventoryId) {

    if (!name || !inventoryId) {
      throw new Error(`ItemDTO has to have name and inventoryId specified. Supplied parameters: name '${name}', inventoryId '${inventoryId}'.`);
    }

    this.itemId = itemId ?? 0;
    this.sortOrder = sortOrder ?? 0;
    this.name = name;
    this.imageUrl = imageUrl;
    this.description = description;
    this.inventoryId = inventoryId;    
  }
}

export class InventoryDTO {
  constructor(inventoryId, name, userId, organizationId, items) {

    if (!name) {
      throw new Error(`InventoryDTO has to have a name specified. Supplied parameters: name '${name}'.`);
    }
    if (!!userId === !!organizationId) {
      throw new Error(`InventoryDTO has to have one and one only of userId or organizationId specified. Supplied parameters: userId '${userId}', organizationId '${organizationId}'.`);
    }
    if (!!items && !(Array.isArray(items) || items.some(b => !(b instanceof ItemDTO)))) {
      throw new Error(`If items are supplied to InventoryDTO it has to be an array of ItemDTOs. Supplied parameters: items '${items}'.`);
    } 

    this.inventoryId = inventoryId;
    this.name = name;
    this.userId = userId;
    this.organizationId = organizationId;
    this.items = items;
  }
}

export class InventoryUserDTO {
  constructor(linkId, inventoryId, name, email) {

    if (!name || !email || !inventoryId) {
      throw new Error(`UserDTO has to have name, email and inventoryId specified. Supplied parameters: name '${name}', email '${email}', inventoryId '${inventoryId}'.`);
    }

    this.linkId = linkId ?? 0;
    this.inventoryId = inventoryId;
    this.name = name;
    this.email = email;
  }
}

export class InviteDTO {
  constructor(inviteId, email, inventoryId, organizationId) {

    if (!email || (!inventoryId && !organizationId)) {
      throw new Error(`InviteDTO has to have email and inventoryId or organizationId specified. Supplied parameters: inviteId '${inviteId}', email '${email}', inventoryId '${inventoryId}', organizationId '${organizationId}'.`);
    } else if (inventoryId && organizationId) {
      throw new Error(`InviteDTO can only have either inventoryId or organizationId specified. Supplied parameters: inviteId '${inviteId}', email '${email}', inventoryId '${inventoryId}', organizationId '${organizationId}'.`);
    }

    this.inviteId = inviteId ?? 0;
    this.email = email;
    this.inventoryId = inventoryId;
    this.organizationId = organizationId;
  }
}

export class ResultItemDTO {
  constructor(obj) {

    const { itemId, name, imageUrl, owner, inventoryId, invUserLinkId } = obj;
    this.itemId = itemId;
    this.name = name;
    this.imageUrl = imageUrl;
    this.owner = owner;
    this.inventoryId = inventoryId;
    this.invUserLinkId = invUserLinkId;
  }
}

export class ClaimLinkDTO {
  constructor(externalId, status, organizationId, creatingUserId) {

    if (!organizationId) {
      throw new Error(`ClaimLinkDTO has to have organizationId specified. Supplied parameters: organizationId '${organizationId}'.`);
    }

    this.externalId = externalId ?? "";
    this.status = status ?? 0;
    this.organizationId = organizationId;
    this.creatingUserId = creatingUserId;
  }

  //adapted from https://stackoverflow.com/a/48287734
  static fromObj(obj) {
    return new this(obj.externalId, obj.status, obj.organizationId, obj.creatingUserId);
  }
}

export class ClaimLinkExpandedDTO extends ClaimLinkDTO {
  constructor(claimLinkId, organizationName, hasUserAlreadyAccess, hasUserAlreadyAccessClaim, ...args) {
    super(...args);
    this.claimLinkId = claimLinkId;
    this.organizationName = organizationName;
    this.hasUserAlreadyAccess = hasUserAlreadyAccess;
    this.hasUserAlreadyAccessClaim = hasUserAlreadyAccessClaim;
  }

  static fromObj(obj) {
    return new this(obj.claimLinkId, obj.organizationName, obj.hasUserAlreadyAccess, obj.hasUserAlreadyAccessClaim, obj.externalId, obj.status, obj.organizationId, obj.creatingUserId);
  }
}

export class AccessClaimDTO {
  constructor(accessClaimId, status, organizationId, userId, claimLinkId) {

    if (!organizationId || !claimLinkId) {
      throw new Error(`AccessClaimDTO has to have organizationId and claimLinkId specified. Supplied parameters: organizationId '${organizationId}', claimLinkId '${claimLinkId}'.`);
    }

    this.accessClaimId = accessClaimId ?? 0;
    this.status = status ?? 0;
    this.organizationId = organizationId;
    this.userId = userId ?? null;
    this.claimLinkId = claimLinkId;
  }

  //adapted from https://stackoverflow.com/a/48287734
  static fromObj(obj) {
    return new this(obj.accessClaimId, obj.status, obj.organizationId, obj.userId, obj.claimLinkId);
  }
}
