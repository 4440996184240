import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ApplicationPaths } from '../utils/AppConstants';

export function Info(props) {
  return (
    <main>
      <Typography variant="h2" component="h1" gutterBottom>Lånepool - låna av vänner</Typography>
      <Typography variant="h5" component="p" gutterBottom>
        På Lånepool väljer du själv vem du litar på och vill låna ut till. 
      </Typography>
      <Typography variant="body1" gutterBottom>
        Har du redskap hemma som du köpt, använt en eller två gånger och som nu bara samlar damm? Lägg dem på Lånepool så 
        slipper grannen köpa samma sällananvändningspryl som du redan har! Chansen är att nästa gång du behöver en 
        sällanpryl så kan du låna den av grannen istället för att köpa en ny själv. Bra för dig, och bra för miljön!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Bra saker att lägga upp är allt som du sällan använder, men som är väldigt bra att ha när en väl behöver det! Till 
        exempel stora sågen, sågbocken, stolpsläggan, borrsatsen, rörtången. Det ska också gärna vara såpass hållbart att 
        du känner dig bekväm med att låna ut det. Men tänk på att det är du som bestämmer vilka du litar på och som får 
        låna!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Om du vill befästa något med lånevillkor så kan du skriva det i beskrivningen av prylen. Till exempel: Köp egna
        slippapper till slipmaskinen, köp egen sytråd till symaskinen. Lånevillkor är egentligen inget som Lånepool blandar
        sig i. Är du ute efter att hyra ut saker bredare än till vänkretsen så finns det dock andra plattformar som nog
        passar dig bättre, till exempel Hygglo.
      </Typography>

      <Typography variant="h3" component="h2" gutterBottom>Läs också</Typography>
      <Typography variant="body1" gutterBottom>
        <Link to={ApplicationPaths.Anvandarvillkor}>Våra användarvillkor</Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link to={ApplicationPaths.Integritetspolicy}>Vår integritetspolicy</Link>
      </Typography>
    </main>
  );
}
