import React from 'react';
import { styled } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import MuiButton from "@material-ui/core/Button";
import { Avatar } from '@material-ui/core';
import md5 from 'md5';

/**
 * Applies the spacing system to the material UI Button
 * Adapted from https://stackoverflow.com/a/63189249/2768430
 */
export const Button = styled(MuiButton)(spacing);

export function Gravatar(props) {
  const { alt, email, ...rest } = props;

  const gravatarHash = md5(email.toLowerCase());
  const gravatarUrl = `https://www.gravatar.com/avatar/${gravatarHash}?d=404`;

  return <Avatar alt={alt} src={gravatarUrl} {...rest} />
}